<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<p-toast></p-toast>
<!-- <p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}"acceptButtonStyleClass="p-button-text" [style]="{ width: '30vw' }" header="Confirmation"
    [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog> -->
<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '35vw'}" header="Confirmation"></p-confirmDialog>
<!-- ********************** Main Content ******************** -->

<!-- <div class="main-content d-flex flex-column"> -->
<!-- <app-dashboard-navbar></app-dashboard-navbar> -->
<ng-container *ngIf="hideDataForAnotherComponent">
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <!-- <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li> -->
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
</ng-container>
<!-- <app-navbar-style-one></app-navbar-style-one> -->
<!-- HWM member list table -->
<section class="hwm-member-list" *ngIf="!successState">
    <div *ngIf="!isShow">
        <!-- Radio button selection to show table  -->
        <div class="pt-3" *ngIf="localStorage.role == 'HWM Owner' || localStorage.role == 'HWM Contact Person'">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <div class="form-check">
                            <input type="radio" id="radio1" class="form-check-input" [checked]="showHwmListing"
                                (click)="showTable('hwm_listing')" name="radioModel" />
                            <label class="form-check-label">
                                Membership Details
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <div class="form-check">
                            <input type="radio" id="radio2" class="form-check-input" [checked]="!showHwmListing"
                                (click)="showTable('renewal_listing')" name="radioModel" />
                            <label class="form-check-label">
                                Membership Renewal Details
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showHwmListing">

            <div class="row align-items-end pt-4 mb-3">
                <div class="col-md">
                    <ng-container *ngFor="let data of multipleFields; let i= index">
                        <div class="row my-2" style="padding: 20px 0;" *ngIf="hideDataForAnotherComponent">
                            <!-- <div style="display:flex; padding: 20px 0;"> -->
                            <div class="col-md-4 mb-2">
                                <p-dropdown [options]="objectKeysArray" (onChange)="getDropdownEvent(data?.key?.name, i)"
                                    optionLabel="name" [(ngModel)]="data.key" [filter]="true" filterBy="name"
                                    [showClear]="clearShow" placeholder="Select Option">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="country-item country-item-value" *ngIf="multipleFields[i]">
                                            <span *ngIf="multipleFields[i]?.key?.name !=
                                                'turn_over'">{{multipleFields[i]?.key?.name.replace('_',
                                                ' ') | titlecase}}</span>
                                            <span *ngIf="multipleFields[i]?.key?.name ==
                                                'turn_over'">{{multipleFields[i]?.key?.name.replace('_',
                                                '') | titlecase}}</span>
                                        </div>
                                    </ng-template>
                                    <ng-template let-value pTemplate="item">
                                        <div class="country-item">
                                            <div *ngIf="value.name != 'turn_over'">{{value.name.replace('_',
                                                ' ') | titlecase }}
                                            </div>
                                            <div *ngIf="value.name == 'turn_over'">{{value.name.replace('_',
                                                '') | titlecase }}
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                            <div class="col col-md-4 mb-2">
                                <input type="text" *ngIf="!(data.dropdownKey?.length> 0)" [(ngModel)]="data.value"
                                    (input)="search($event.target.value, i , '')" class="form-control"
                                    placeholder="Search Keyword">
                                <p-dropdown *ngIf="data.dropdownKey?.length> 0" [options]="data.dropdownKey" optionValue="value"
                                    (onChange)="search($event.value, i , '', $event)" filterBy="label" [filter]="true"
                                    [(ngModel)]="data.value" placeholder="Select Option" optionLabel="label">
                                </p-dropdown>
                            </div>
                            <div class="col-auto mb-2">
                                <p-button *ngIf="data.value?.length> 0 && data.key &&
                                    dataTable.filteredValue?.length > 0" (click)="addMoreFilters()" class="button" icon="bx
                                    bx-plus" iconPos="right" class="mr-2" type="button">
                                </p-button>
        
                                <p-button *ngIf="i!= 0" type="button" class="ml-2" icon="bx
                                    bx-minus" iconPos="right" (click)="removevalue(i)">
                                </p-button>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="col-md-auto">
                    <p-button type="button" class="mx-2" (click)="applyFilter()" tooltipPosition="bottom" label="Apply Filter"></p-button>
                </div>
            </div>

            

             

            <div class="theader" *ngIf="hideDataForAnotherComponent">
                
                <div class="d-flex">
                    <span class="p-input-icon-left ml-auto mr-2">
                        <i class="pi pi-search"></i>
                        <input class="form-control" pInputText type="text"  placeholder="Search Keyword" [(ngModel)]="filterVal"  (input)="nameFilter.filterGlobal($event.target.value,
                        'contains')" />
                    </span>
                    <p-button type="button" class="mx-2" (click)="reset(nameFilter)" icon="bx bx-reset"
                        [pTooltip]="tooltipText" tooltipPosition="bottom">
                    </p-button>
                    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="onXLSExport()"
                        class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"
                        *ngIf="localStorage.role == 'Admin'"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onPDFExport()"
                        class="p-button-warning mr-2" pTooltip="PDF" tooltipPosition="bottom"
                        *ngIf="localStorage.role == 'Admin'"></button>
                        <!-- <p-button type="button" class="mx-2" (click)="applyFilter()" tooltipPosition="bottom" label="Apply Filter"></p-button> -->


                     
                        
                </div>
                <ng-container>
                    <p-button type="button" class="ml-2" routerLink="/user-panel/hwm-registration" *ngIf="enableAddButton" icon="bx bx-plus"
                        label="Add" iconPos="right"></p-button>
                </ng-container>
            </div>
            <!-- HWM Listing -->
            <!-- *ngIf="((localStorage.role == 'Admin' || localStorage.role == 'UCCI Staff') ? response?.data?.length > 0 : response?.member_data?.length == 0)" -->
            <ng-container>
                <p-table #nameFilter [rows]="10" dataKey="id" rowGroupMode="subheader" [showCurrentPageReport]="true"
                    sortOrder="1" [loading]="loading" sortMode="multiple" styleClass="p-datatable-sm
                    p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll" scrollable="true" scrollHeight="600px"
                    [paginator]="true" currentPageReportTemplate="{totalRecords} of {{dtDataLength}}
                    records" [globalFilterFields]="['company_name' , 'email']"
                    [value]="hwmMemberListData == false ? hwmMemberList : verifiedMembers"
                    [tableStyle]="{'min-width': '50rem', 'width' : '100%'}">
                    <!-- currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" -->
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="min-width: 62px;">S. No.</th>
                            <th style="min-width: 100px;">Action</th>
                            <th style="min-width: 120px;" *ngIf="(localStorage.role != 'Admin') &&
                                (localStorage.role != 'UCCI Staff') &&
                                (localStorage.role != 'President')" >
                                Payment</th>
                            <th style="min-width: 160px;" pSortableColumn="status">
                                Status<p-sortIcon
                                field="status">
                            </p-sortIcon>
                            </th>
                            <th style="min-width: 200px;" pSortableColumn="company_name">Company Name<p-sortIcon
                                    field="company_name">
                                </p-sortIcon>
                            </th>
                            <th style="text-align: center; min-width: 180px;"  pSortableColumn="member_since">Member Since<p-sortIcon
                                field="member_since">
                            </p-sortIcon></th>

                            <th style="text-align: center; min-width: 180px;"  pSortableColumn="membership_no">Membership No<p-sortIcon
                                field="membership_no">
                            </p-sortIcon></th>
                            <th pSortableColumn="email">Email<p-sortIcon
                                field="email">
                            </p-sortIcon></th>
                            <th style="min-width: 160px; text-align: center"  pSortableColumn="created_at">
                                Date<p-sortIcon
                                field="created_at">
                            </p-sortIcon>
                            </th>
                            <th style="min-width: 160px;"  pSortableColumn="status">
                                Renewal Status<p-sortIcon
                                field="status">
                            </p-sortIcon>
                            </th>
                            <th style="min-width: 160px;text-align: center;"  pSortableColumn="amount">
                                Invoice Payment<p-sortIcon
                                field="amount">
                            </p-sortIcon>
                            </th>
                            <th *ngIf="localStorage.role == 'Admin' || localStorage.role == 'UCCI Staff' || this.localStorage.role == 'President'"
                                style="min-width: 160px;text-align: center;">
                                Checklist
                            </th>
                            <th style="min-width: 160px;text-align: center;" pTooltip="Pollution Control Board Letter"
                                tooltipPosition="bottom">
                                PCB Letter
                            </th>
                            <!-- <th *ngIf="localStorage.role == 'Admin' || localStorage.role == 'UCCI Staff' || this.localStorage.role == 'President'"
                                style="min-width: 160px;text-align: center;">
                                Notesheet
                            </th> -->
                        </tr>
                        <tr *ngIf="hideDataForAnotherComponent">
                            <th *ngFor="let item of colFilter" [ngSwitch]="item.field">
                                <!-- <div *ngSwitchCase="'S. No.'"></div> -->
                                <!-- <div *ngSwitchCase="'action'"></div> -->
                                <p-columnFilter [showClearButton]="false" *ngSwitchCase="'status'"  field="status"
                                    matchMode="equals" [showMenu]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" [appendTo]="nameFilter" [options]="status"
                                            (onChange)="filter($event.value)" placeholder="Select" [showClear]="false"
                                            [resetFilterOnHide]="true">
                                            <ng-template let-option pTemplate="item">
                                                <span>{{option.label}}</span>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                                <!-- <p-columnFilter [showClearButton]="false" *ngSwitchCase="'renewal_status'" field="is_renewal"
                                    matchMode="equals" [showMenu]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" [appendTo]="nameFilter" [options]="renewal_status"
                                            (onChange)="filter($event.value)" placeholder="Select" [showClear]="false"
                                            [resetFilterOnHide]="true">
                                            <ng-template let-option pTemplate="item">
                                                <span>{{option.label}}</span>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter> -->
                                <input placeholder="Keyword" *ngSwitchCase="'company_name'" field="company_name" pInputText
                                    class="p-inputtext p-component p-element" type="text" [(ngModel)]="company_name"
                                    (input)="nameFilter.filter($event.target.value,
                                    'company_name', 'contains')" style="height: 30px;">

                                <input placeholder="Keyword" *ngSwitchCase="'email'" pInputText field="email"
                                    class="p-inputtext p-component p-element" type="text" [(ngModel)]="email" (input)="nameFilter.filter($event.target.value,
                                    'email', 'contains')" style="height: 30px;">
                                <input placeholder="Keyword" *ngSwitchCase="'membership_no'" pInputText field="membership_no"
                                    class="p-inputtext p-component p-element" type="text" [(ngModel)]="membership_no"
                                    (input)="nameFilter.filter($event.target.value,
                                    'membership_no', 'contains')" style="height: 30px;">
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-hwmMemberList let-i="rowIndex">
                        <ng-container *ngIf="hwmMemberList.flag == 0 ">
                            <tr>
                                <!-- <td><button type="button" pButton pRipple
                                        class="p-button-text p-button-rounded
                                        p-button-plain p-mr-2" [icon]="expanded
                                        ? 'pi pi-chevron-down' :
                                        'pi pi-chevron-right'"
                                        [pRowToggler]="hwmMemberList"></button></td> -->
                                <td style="min-width: 80px;">{{i + 1}}</td>
                                <td>
                                    <div class="view-buttons">
                                        <!-- view button -->
                                        <button pButton pRipple class="p-button-rounded
                                        p-button-text p-button-info" *ngIf="viewPermission"
                                            (click)="showMaximizableDialog(hwmMemberList,'view')" pTooltip="View"
                                            tooltipPosition="bottom">
                                            <i class="far fa-eye"></i>
                                        </button>
                                        <!-- edit button -->
                                        <button
                                            *ngIf="updatePermission"
                                            pButton pRipple class="p-button-rounded
                                        p-button-text p-button-info" pTooltip="Edit" tooltipPosition="bottom"
                                            icon="bx bx-pencil" (click)="routerToHWMMember(hwmMemberList.id)">
                                        </button>
                                        <!-- mark as paid button -->
                                        <button *ngIf="this.localStorage.username == 'Chief Executive Officer' || this.localStorage.username == 'Admin'" pButton pRipple pTooltip="Mark as Paid"
                                            [disabled]="hwmMemberList?.status != 'Pending'"
                                            (click)="showPaymentDialog(hwmMemberList)" tooltipPosition="bottom" class="p-button-rounded
                                        p-button-text
                                        p-button-success mr-2"><i class='bx
                                            bxs-check-circle'></i></button>
                                            <button pButton pRipple class="p-button-rounded p-button-text p-button-danger"
                                            [disabled]="hwmMemberList?.status != 'Approved' && hwmMemberList?.status !== 'Provisionally Terminated'"
                                            (click)="terminatted(hwmMemberList.id,'terminated')" pTooltip="Terminate" *ngIf="localStorage.username == 'Chief Executive Officer' || localStorage.username == 'Admin' || localStorage.username == 'President'"
                                             tooltipPosition="bottom">
                                            <i class="bx bxs-message-alt-x"></i>
                                            </button>
                                            <button pButton pRipple class="p-button-rounded p-button-text p-button-danger"
                                            [disabled]="hwmMemberList?.status != 'Approved'"
                                            (click)="terminatted(hwmMemberList.id,'provisionally terminated')" pTooltip="Provisionally Terminate" *ngIf="localStorage.username == 'Chief Executive Officer' || localStorage.username == 'President' || localStorage.username == 'Admin'"
                                             tooltipPosition="bottom">
                                            <i class="bx bx-message-alt-x"></i>
                                            </button>
                                            
                                        <!--********************** approval and rejection process for president role ********************-->
                                        <!-- approval button -->
                                        <ng-container *ngIf="localStorage.role == 'President'">
                                            <button pButton tooltipPosition="bottom" pRipple (click)="approveMemberById('hwm',
                                            hwmMemberList.id,
                                            1, hwmMemberList,hwmMemberList.status)" pTooltip="Approve"
                                                [disabled]="(hwmMemberList.status !== 'In Progress' && hwmMemberList.status != 'Verified')"
                                                class="p-button-rounded
                                            p-button-text
                                            p-button-success"><i class="far
                                                fa-check-circle"></i>
                                            </button>
                                            <!-- rejection button -->
                                            <!-- <button pButton pRipple (click)="approveMemberById('hwm',
                                            hwmMemberList.id,
                                            0, hwmMemberList)" class="p-button-rounded
                                            p-button-text
                                            p-button-danger" [disabled]="(hwmMemberList.status ==
                                            'Approved' || hwmMemberList.status ==
                                            'Rejected' || hwmMemberList.status ==
                                            'Pending') || localStorage.role == 'UCCI Staff'" pTooltip="Reject"
                                                tooltipPosition="bottom">
                                                <i class="bx bx-message-alt-x"></i>
                                            </button> -->
                                        </ng-container>
                                        <!--********************** Verification and rejection process for staff role ********************-->
                                        <!--  -->
                                        <ng-container *ngIf="localStorage.role ==
                                    roleObject.staff || localStorage.role == 'Admin'">
                                            <button pButton pRipple [disabled]="(hwmMemberList.status != 'In Progress')"
                                                (click)="approveMemberById('verification',
                                            hwmMemberList.id, 1, hwmMemberList, hwmMemberList.status)" class="p-button-rounded p-button-text
                                            p-button-success" pTooltip="Verify" tooltipPosition="bottom">
                                                <i class="fas fa-badge-check"></i>
                                            </button>
                                        </ng-container>
                                        <!-- <ng-container *ngIf="hwmMemberList.status == inprogress && localStorage.role ==
                                        'Admin'">
                                        <button pButton tooltipPosition="bottom" pRipple (click)="approveMemberById('hwm',
                                            hwmMemberList.id,
                                            1)" pTooltip="Approve" class="p-button-rounded
                                            p-button-text
                                            p-button-success">
                                            <i class="far fa-check-circle"></i>
                                        </button>
                                        <button pButton pRipple (click)="approveMemberById('hwm', hwmMemberList.id,
                                            0)" class="p-button-rounded
                                            p-button-text
                                            p-button-danger" pTooltip="Reject" tooltipPosition="bottom">
                                            <i class="bx bx-message-alt-x"></i>
                                        </button>
                                    </ng-container> -->
                                        <!-- approved, disabled buttons -->
                                        <!-- <ng-container *ngIf="approvalPermission">
                                        <button pButton [disabled]="hwmMemberList.status ==
                                    'Pending' || hwmMemberList.status ==
                                    'Approved' || hwmMemberList.status ==
                                    'Rejected'" class="p-button-rounded
                                            p-button-text
                                            p-button-success">
                                            <i class="far fa-check-circle"></i>
                                        </button>
                                        <button pButton pRipple [disabled]="hwmMemberList.status ==
                                    'Pending' || hwmMemberList.status ==
                                    'Approved' || hwmMemberList.status ==
                                    'Rejected'" class="p-button-rounded
                                            p-button-text
                                            p-button-danger">
                                            <i class="bx bx-message-alt-x"></i>
                                        </button>
                                    </ng-container> -->
                                        <!-- pending, disabled buttons -->
                                        <!-- <ng-container *ngIf="hwmMemberList.status ==
                                        'Pending' && localStorage.role ==
                                        'Admin'">
                                    <button pButton disabled class="p-button-rounded
                                            p-button-text
                                            p-button-success">
                                        <i class="far fa-check-circle"></i>
                                    </button>
                                    <button pButton pRipple disabled class="p-button-rounded
                                            p-button-text
                                            p-button-danger">
                                        <i class="bx bx-message-alt-x"></i>
                                    </button>
                                </ng-container> -->
                                        <!-- rejected, disabled buttons -->
                                        <!-- <ng-container *ngIf="hwmMemberList.status ==
                                        'Rejected' && localStorage.role ==
                                        'Admin'">
                                    <button pButton disabled class="p-button-rounded
                                            p-button-text
                                            p-button-success">
                                        <i class="far fa-check-circle"></i>
                                    </button>
                                    <button pButton pRipple disabled class="p-button-rounded
                                            p-button-text
                                            p-button-danger">
                                        <i class="bx bx-message-alt-x"></i>
                                    </button>
                                </ng-container> -->
                                        <!-- <ng-container *ngIf="deletePermission">
                                            <button pButton pTooltip="delete"
                                                (click)="deleteConfirmation(hwmMemberList)" class="p-button-rounded
                                            p-button-text
                                            p-button-danger">
                                                <i class="pi pi-trash"></i>
                                            </button>
                                        </ng-container> -->
                                    </div>
                                </td>
                                <td *ngIf="(localStorage.role != 'Admin') &&
                                (localStorage.role != 'UCCI Staff') &&
                                (localStorage.role != 'President')">
                                    <button pRipple class="mx-1 pay_now_btn" *ngIf="hwmMemberList.status == 'Pending';
                                    else elseBlock" (click)="paymentDetails(hwmMemberList?.hwm_admission?.id,hwmMemberList?.hwm_trust?.id,
                                    hwmMemberList)">Pay Now</button>
                                    <ng-template #elseBlock>
                                        <button pRipple class="mx-1 paid_btn" disabled>Paid</button>
                                    </ng-template>
                                </td>
                                <td class="status">
                                    <ng-container *ngIf="
                                    hwmMemberList?.status =='On Hold'">
                                        <span class="on-hold">
                                            {{ hwmMemberList?.status }}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="
                                    hwmMemberList?.status =='In Progress'">
                                        <span class="in-progress">
                                            {{ hwmMemberList?.status }}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="hwmMemberList?.status
                                    == 
                                    'Approved'">
                                        <span class="approved">
                                            {{ hwmMemberList?.status }}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="hwmMemberList?.status
                                    == 
                                    'Verified'">
                                        <span class="verify">
                                            {{ hwmMemberList?.status }}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="hwmMemberList?.status
                                    ==
                                    'Pending'">
                                        <span class="pending">
                                            {{ hwmMemberList?.status }}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="hwmMemberList?.status
                                    ==
                                    'Rejected'">
                                        <span class="rejected">
                                            {{ hwmMemberList?.status }}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="hwmMemberList?.status
                                    ==
                                    'Terminated'">
                                        <span class="terminated">
                                            {{ hwmMemberList?.status }}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="hwmMemberList?.status
                                    ==
                                    'Provisionally Terminated'">
                                        <span class="terminated" style="background-color: #692f35 !important">
                                            {{ hwmMemberList?.status }}
                                        </span>
                                    </ng-container>
                                </td>
                                <td>{{hwmMemberList.company_name | titlecase}}</td>
                                <td style="text-align: center;">{{ hwmMemberList?.member_since ?
                                    (hwmMemberList?.member_since | date:'dd-MM-yyyy') : '-' }}</td>
                                <td style="text-align: center;">{{hwmMemberList?.membership_no ?? '-'}}</td>
                                <td>{{hwmMemberList.email}}</td>
                                <td style="text-align: center;">{{hwmMemberList?.created_at | date:'dd-MM-yyyy' }}</td>
                                <td class="status">
                                    <ng-container *ngIf="hwmMemberList?.is_renewal == '0'">
                                        <span style="text-align: center;"
                                            [ngClass]="hwmMemberList?.status == 'Approved' ? 'approved' : ''">
                                            {{hwmMemberList?.status == 'Approved' ? 'Renewed' : '-'}}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="hwmMemberList?.is_renewal == '1'">
                                        <span style="text-align: center;"
                                            [ngClass]="hwmMemberList?.status == 'Approved' ? 'pending' : ''">
                                            {{hwmMemberList?.status == 'Approved'? 'Pending' : '-'}}
                                        </span>
                                    </ng-container>
                                </td>
                                <td style="text-align: center;">
                                    {{(hwmMemberList?.hwm_admission?.amount --
                                    hwmMemberList?.hwm_trust?.amount | currency : 'INR')
                                    ?? '-' }}
                                </td>

                                <!-- <td style="text-align:center;">
                                <a href="{{ucciBaseUrl}}/storage/invoices/{{hwmMemberList?.member_membership[0]?.invoice_pdf}}"
                                    style=" font-size: 0.8rem;
                                color: var(--mainColor);  overflow-wrap: break-word;;
                                font-weight: 700; text-decoration: underline;" target="_blank"
                                    download="{{ucciBaseUrl}}/storage/hwm/{{hwmMemberList?.id}}/checklist.pdf"
                                    *ngIf="hwmMemberList.status == 'In Progress'">
                                    Invoice</a>
                                <span *ngIf="(hwmMemberList.status != 'In Progress')">-</span>
                            </td> -->
                                <td style="text-align:center;"
                                    *ngIf="localStorage.role == 'Admin' || localStorage.role == 'UCCI Staff' || this.localStorage.role == 'President'">
                                    <a href="{{ucciBaseUrl}}/storage/hwm/{{hwmMemberList?.id}}/checklist.pdf" style=" font-size: 0.8rem;
                                color: var(--mainColor);  overflow-wrap: break-word;;
                                font-weight: 700; text-decoration: underline;" target="_blank"
                                        download="{{ucciBaseUrl}}/storage/hwm/{{hwmMemberList?.id}}/checklist.pdf"
                                        *ngIf="hwmMemberList.status != 'Pending'">
                                        Checklist</a>
                                    <span *ngIf="(hwmMemberList.status == 'Pending')">-</span>
                                </td>
                                <td style="text-align:center;">
                                    <a *ngIf="hwmMemberList.status == 'Approved'; else elseBlock"
                                        href="{{ucciBaseUrl}}/storage/hwm/{{hwmMemberList?.id}}/pollution_letter.pdf"
                                        style=" font-size: 0.8rem;
                                color: var(--mainColor);  overflow-wrap: break-word;;
                                font-weight: 700; text-decoration: underline;" target="_blank"
                                        download="{{ucciBaseUrl}}/storage/hwm/{{hwmMemberList?.id}}/pollution_letter.pdf">
                                        Letter
                                    </a>
                                    <ng-template #elseBlock>
                                        <span style=" font-size: 0.8rem;
                                    color: var(--mainColor);  overflow-wrap: break-word;;
                                    font-weight: 700; text-decoration: underline; cursor: pointer;"
                                            (click)="showMaximizableDialog(hwmMemberList,'letter')">Drafted
                                            Letter</span>
                                    </ng-template>
                                </td>
                                <!-- <td style="text-align:center;"
                                    *ngIf="localStorage.role == 'Admin' || localStorage.role == 'UCCI Staff' || this.localStorage.role == 'President'">
                                    <a href="{{ucciBaseUrl}}/storage/hwm/new_membership_notesheet/{{hwmMemberList?.id}}/hwm_membership_notesheet.pdf"
                                        style=" font-size: 0.8rem;
                                color: var(--mainColor);  overflow-wrap: break-word;;
                                font-weight: 700; text-decoration: underline;" target="_blank"
                                        download="{{ucciBaseUrl}}/storage/hwm/{{hwmMemberList?.id}}/hwm_membership_notesheet.pdf">
                                        Notesheet</a>
                                
                                </td> -->
                            </tr>
                        </ng-container>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="9">No Data Found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-container>
            <!-- Drafted data listing -->
            <!-- <ng-container *ngIf="response?.member_data?.length > 0">
                <p-table #nameFilter [rows]="10" dataKey="id" rowGroupMode="subheader" [showCurrentPageReport]="true"
                    sortOrder="1" sortMode="multiple" styleClass="p-datatable-sm
                p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll" [loading]="loading"
                    [paginator]="true" currentPageReportTemplate="{totalRecords} of {{dtDataLength}}
                records" [globalFilterFields]="['company_name']" [value]="draftData"
                    [tableStyle]="{'min-width': '50rem', 'width' : '100%'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="min-width: 62px;">S. No.</th>
                            <th style="min-width: 160px;">
                                Status
                            </th>
                            <th style="min-width: 200px;">Company Name
                            </th>
                            <th style="min-width: 200px;">Username
                            </th>
                            <th style="min-width: 200px;">Useremail
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-data let-i="rowIndex">
                        <tr>
                            <td style="min-width: 80px;">{{i + 1}}</td>
                            <td class="status">
                                <span class="pending">
                                    {{response?.member_data[0]?.changed_in}}
                                </span>
                            </td>
                            <td>{{data?.company_name}}</td>
                            <td>{{storageData?.username}}</td>
                            <td>{{storageData?.useremail}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-container> -->
        </div>
        <!-- renewal listing toggle -->
        <!-- <div>

        </div> -->

        <!-- HWM Expiring Membersip Listing -->
        <ng-container *ngIf="localStorage.role == 'HWM Owner' || localStorage.role == 'HWM Contact Person'">
            <p-table [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" *ngIf="showRenewalListing" #nameFilter
                [rows]="10" dataKey="id" rowGroupMode="subheader" [showCurrentPageReport]="true" sortOrder="1"
                sortMode="multiple" styleClass="p-datatable-sm
               p-datatable-customers" responsiveLayout="scroll" scrollable="true" scrollHeight="600px" [loading]="loading" [value]="renewalListing"
                [tableStyle]="{'min-width': '50rem', 'width' : '100%'}">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width: 62px;">S. No.</th>
                        <th style="min-width: 160px;">
                            Status
                        </th>
                        <th style="min-width: 160px;">
                            Membership Year
                        </th>
                        <th>Payment</th>
                        <th>Expiring Date</th>

                        <th style="min-width: 160px;">
                            Renewal Certificate
                        </th>
                        <th style="min-width: 160px;">
                            Invoice
                        </th>
                        <th style="min-width: 160px;">
                            Trust Invoice
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-data let-i="rowIndex">
                    <tr>
                        <td style="min-width: 80px;">{{i + 1}}</td>
                        <td class="status">
                            <ng-container *ngIf="data?.verification_status =='Pending'">
                                <span class="pending">
                                    {{ data?.verification_status }}
                                </span>
                            </ng-container>
                            <ng-container *ngIf="data?.verification_status =='Approved'">
                                <span class="approved">
                                    {{ data?.verification_status }}
                                </span>
                            </ng-container>
                        </td>
                        <td>{{data?.membership_year}}</td>
                        <td>{{(data?.payment?. amount | currency : 'INR')
                            ?? '-' }}</td>
                        <td>{{data?.expire_at | date:'dd-MM-yyyy' }}</td>
                        <td>
                            <a href="{{ucciBaseUrl}}/storage/hwm_renewal/{{data?.member?.membership_no}}/HWM_Renewal_{{data?.member?.membership_no}}_{{data?.membership_year.slice(13,22)}}.pdf"
                                style=" font-size: 0.8rem;
                            color: var(--mainColor);  overflow-wrap: break-word;;
                            font-weight: 700; text-decoration: underline;" target="_blank"
                                download="{{ucciBaseUrl}}/storage/hwm_renewal/{{data?.member?.membership_no}}/HWM_Renewal_{{data?.member?.membership_no}}_{{data?.membership_year.slice(13,22)}}.pdf"
                                *ngIf="data?.verification_status =='Approved'">
                                Renewal Certificate</a>
                            <span *ngIf="data?.verification_status !='Approved'">-</span>
                        </td>
                        <td>
                            <a *ngIf="data?.invoice_pdf !=null" style="font-size: 0.8rem;
                                    color: var(--mainColor);
                                    font-weight: 700;" class="btn
                                    btn-default" (click)="downLoadPdf(data?.invoice_pdf,'invoice')">
                                Invoice
                            </a>
                            <span *ngIf="data?.invoice_pdf ==null">-</span>
                        </td>
                        <td>
                            <a *ngIf="data?.trust_invoice_pdf !=null" style="font-size: 0.8rem;
                                    color: var(--mainColor);
                                    font-weight: 700;" class="btn
                                    btn-default" (click)="downLoadPdf(data?.trust_invoice_pdf,'invoice')">
                                Trust Invoice
                            </a>
                            <span *ngIf="data?.trust_invoice_pdf ==null">-</span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="9">No Data Found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </ng-container>
        <!-- ***************************************************************************************************************** -->
        <!----------------------------------------------------------- view dialog ----------------------------------------------->
        <!-- ***************************************************************************************************************** -->
        <p-dialog header="HWM Membership Form Details" [(visible)]="displayMaximizable" [modal]="true"
            [style]="{ width: '90vw' }" [draggable]="false" [resizable]="false">
            <!-- Start -->
            <ng-container *ngFor="let data of hwmMemberData">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="my-profile-box">
                            <h3>Basic Information</h3>
                            <form>
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>Company Name :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.company_name}}" />
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>GST Number :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.gst_number
                                                }}" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>CIN :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.cin_number
                                                }}" />
                                        </div>
                                    </div>


                                </div>
                            </form>
                        </div>
                    </div>




                    <div class="row">
                        <div class="col-lg-12">
                            <div class="my-profile-box">
                                <h3>Registered Address</h3>
                                <form>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Factory Address :</label>
                                                <input type="text" value="{{
                                                    data?.hwm_details[0]?.factory_address?.address
                                                    }}" class="form-control" readonly />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Unit :</label>
                                                <input type="text" value="{{
                                                    data?.hwm_details[0]?.unit
                                                    }}" class="form-control" readonly />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Factory City :</label>
                                                <input type="text" value="{{
                                                    data?.hwm_details[0]?.factory_address?.city
                                                    }}" class="form-control" readonly />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Factory State :</label>
                                                <input type="text" value="{{
                                                    data?.hwm_details[0]?.factory_address?.state
                                                    }}" class="form-control" readonly />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Factory Pin Code :</label>
                                                <input type="text" value="{{
                                                    data?.hwm_details[0]?.factory_address?.pin_code}}"
                                                    class="form-control" readonly />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>





                    <div class="row">
                        <div class="col-lg-12">
                            <div class="my-profile-box">
                                <h3>Company Details</h3>
                                <form>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Registration Number :</label>
                                                <input type="text" readonly class="form-control" value="{{
                                                    data?.registration_number}}" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Capital Investment :</label>
                                                <input type="text" readonly class="form-control" value="{{
                                                    data?.capital_investment}}" />
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Website :</label>
                                                <input type="text" readonly class="form-control"
                                                    value="{{ data?.website }}" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Email :</label>
                                                <input type="text" readonly class="form-control"
                                                    value="{{ data?.email }}" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Nature of Business :</label>
                                                <input type="text" readonly class="form-control" value="{{
                                                    data?.nob?.name }}" />
                                            </div>
                                        </div>

                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Company Logo :</label>
                                                <div>
                                                    <ng-container *ngIf="data?.media">
                                                        <img [src]="getImage(data?.media,'logo')" style="height: 70px;
                                                        width:70px;">
                                                    </ng-container>
                                                    <ng-container *ngIf="!data?.media">
                                                        <img src="../../../../../../assets/img/imageNotFound.png" style="height: 70px;
                                                                width:70px;">
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mx-2">
                        <table>
                            <tr>
                                <th>Fee Applicable</th>
                                <th>Amount</th>
                                <th>GST Amount</th>
                                <th>Total</th>
                            </tr>
                            <tr>
                                <td>{{data?.hwm_admission?.paid_for}}</td>
                                <td>
                                    {{(data?.hwm_admission?.amount - data?.hwm_admission?.tax_amount)
                                    | indianCurrency}}
                                </td>
                                <td>
                                    {{data?.hwm_admission?.tax_amount |
                                    indianCurrency}}
                                </td>
                                <td>{{data?.hwm_admission?.amount |
                                    indianCurrency}}
                                </td>
                            </tr>
                            <tr>
                                <td>{{data?.hwm_trust?.paid_for}}</td>
                                <td>
                                    {{(data?.hwm_trust?.amount -
                                    data?.hwm_trust?.tax_amount)
                                    | indianCurrency}}
                                </td>
                                <td>
                                    {{data?.hwm_trust?.tax_amount |
                                    indianCurrency}}
                                </td>
                                <td>{{data?.hwm_trust?.amount |
                                    indianCurrency}}
                                </td>
                            </tr>
                            <tr>
                                <th style="border-right:
                                    none;" colspan="3">
                                    Sub total
                                </th>
                                <td style="border-left: none
                                    ; color: #000;"><b>{{data?.hwm_admission?.amount
                                        -- data?.hwm_trust?.amount
                                        | indianCurrency}}</b>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-lg-12">
                        <div class="my-profile-box">
                            <h3>Invoice</h3>
                            <form>
                                <ng-container *ngFor="let invoice of data?.member_membership">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <a style=" font-size: 0.8rem;   
                                                        color: var(--mainColor);
                                                        font-weight: 700;" class="btn
                                                        btn-default" *ngIf="invoice?.invoice_pdf != null"
                                                    (click)="downLoadPdf(invoice?.invoice_pdf,'invoice')">
                                                    {{invoice?.invoice_pdf}}
                                                </a>
                                                <span class="form-control" *ngIf="invoice?.invoice_pdf == null">-</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <a style=" font-size: 0.8rem;   
                                                        color: var(--mainColor);
                                                        font-weight: 700;" class="btn
                                                        btn-default" *ngIf="invoice?.trust_invoice_pdf != null"
                                                    (click)="downLoadPdf(invoice?.trust_invoice_pdf,'invoice')">
                                                    Trust Invoice
                                                </a>
                                                <span class="form-control"
                                                    *ngIf="invoice?.trust_invoice_pdf == null">-</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <a style=" font-size: 0.8rem;   
                                                        color: var(--mainColor);
                                                        font-weight: 700;" class="btn
                                                        btn-default" *ngIf="invoice?.trust_proforma_pdf != null"
                                                    (click)="downLoadPdf(invoice?.trust_proforma_pdf,'invoice')">
                                                    Trust Proforma
                                                </a>
                                                <span class="form-control"
                                                    *ngIf="invoice?.trust_proforma_pdf == null">-</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <a style=" font-size: 0.8rem;   
                                                        color: var(--mainColor);
                                                        font-weight: 700;" class="btn
                                                        btn-default" *ngIf="invoice?.proforma_pdf != null"
                                                    (click)="downLoadPdf(invoice?.proforma_pdf,'invoice')">
                                                    Proforma
                                                </a>
                                                <span class="form-control"
                                                    *ngIf="invoice?.proforma_pdf == null">-</span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </form>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-lg-12">
                            <div class="my-profile-box">
                                <h3>Payment Details</h3>
                                <form>
                                    <div class="row">
                                        <ng-container *ngFor="let data of this.hwmDetails"></ng-container>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Paid For :</label>
                                                <input type="text" readonly class="form-control" value="{{
                                                data?.payment[0]?.paid_for ? data?.payment[0]?.paid_for : '-'}}" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Amount :</label>
                                                <input type="text" readonly class="form-control" value="{{
                                                        data?.payment[0]?.amount ? data?.payment[0]?.amount : '-'}}" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12 status">
                                            <div class="form-group">
                                                <label>Payment Status :</label>
                                                <ng-container *ngIf="
                                                    data?.payment[0]?.payment_status =='On Hold'">
                                                    <span class="on-hold">
                                                        {{ data?.payment[0]?.payment_status }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="
                                                    data?.payment[0]?.payment_status =='In Progress'">
                                                    <span class="in-progress">
                                                        {{ data?.payment[0]?.payment_status }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="data?.payment[0]?.payment_status
                                                    ==
                                                    'Approved'">
                                                    <span class="approved">
                                                        {{ data?.payment[0]?.payment_status }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="data?.payment[0]?.payment_status
                                                    ==
                                                    'Pending'">
                                                    <span class="pending">
                                                        {{ data?.payment[0]?.payment_status }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="data?.payment[0]?.payment_status
                                                    ==
                                                    'Rejected'">
                                                    <span class="rejected">
                                                        {{ data?.payment[0]?.payment_status }}
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Paid For :</label>
                                                <input type="text" readonly class="form-control" value="{{
                                                data?.payment[0]?.paid_for ? data?.payment[0]?.paid_for : '-'}}" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Amount :</label>
                                                <input type="text" readonly class="form-control" value="{{
                                                        data?.payment[0]?.amount ? data?.payment[0]?.amount : '-'}}" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-12 status">
                                            <div class="form-group">
                                                <label>Payment Status :</label>
                                                <ng-container *ngIf="
                                                    data?.payment[0]?.payment_status =='On Hold'">
                                                    <span class="on-hold">
                                                        {{ data?.payment[0]?.payment_status }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="
                                                    data?.payment[0]?.payment_status =='In Progress'">
                                                    <span class="in-progress">
                                                        {{ data?.payment[0]?.payment_status }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="data?.payment[0]?.payment_status
                                                    ==
                                                    'Approved'">
                                                    <span class="approved">
                                                        {{ data?.payment[0]?.payment_status }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="data?.payment[0]?.payment_status
                                                    ==
                                                    'Pending'">
                                                    <span class="pending">
                                                        {{ data?.payment[0]?.payment_status }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="data?.payment[0]?.payment_status
                                                    ==
                                                    'Rejected'">
                                                    <span class="rejected">
                                                        {{ data?.payment[0]?.payment_status }}
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> -->
                    <!-- hwm owner details -->
                    <div class="col-lg-6 col-md-6">
                        <div class="my-profile-box">
                            <h3>Details Of Occupier/Owner</h3>
                            <form>
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label>Occupier/Owner Name :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.nominee1?.name }}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>Designation :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.nominee1?.designation
                                                }}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>PAN :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.nominee1?.pan_number
                                                }}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>Email :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.nominee1?.email }}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Office Phone :</label>
                                            <input type="text" readonly class="form-control" value="{{data?.nominee1?.office_phone
                                                ? data?.nominee1?.office_phone :
                                                '-' }}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Mobile Number:</label>
                                            <input type="text" readonly class="form-control" value="{{data?.nominee1?.mobile
                                                }}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>Address :</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{data?.nominee1_address?.address}}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>City :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.nominee1_address?.city ?
                                                data?.nominee1_address?.city :
                                                '-' }}" />
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>State :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.nominee1_address?.state ?
                                                data?.nominee1_address?.state :
                                                '-' }}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>Pin Code :</label>
                                            <input type="text" readonly class="form-control" value="{{data?.nominee1_address?.pin_code
                                                ?
                                                data?.nominee1_address?.pin_code
                                                : '-'}}" />
                                        </div>
                                    </div>


                                    <div class="col-xl-6 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Department :</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{data?.nominee1?.department}}" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label>Image :</label>
                                            <ng-container *ngIf="data?.nominee1?.media">
                                                <img [src]="getImage(data?.nominee1?.media,'profile1')" style="height: 70px;
                                                width:70px;">
                                            </ng-container>
                                            <ng-container *ngIf="!data?.nominee1?.media">
                                                <img src="../../../../../../assets/img/imageNotFound.png" style="height: 70px;
                                                    width:70px;">
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Preferred Communication :</label>
                                            <ul>
                                                <li *ngFor="let item of
                                                    data?.nominee1?.preferred_communication
                                                    | jsonParse">
                                                    {{item}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Interest Area :</label>
                                            <ul>
                                                <li *ngFor="let item of
                                                    data?.nominee1_interest">
                                                    {{item.interest_area}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <label>Biodata :</label>
                                            <textarea type="text" readonly class="form-control" value="{{
                                                data?.nominee1?.biodata }}">
                                            </textarea>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- hwm contact person details -->
                    <div class="col-lg-6 col-md-6">
                        <div class="my-profile-box">
                            <h3>HWM Contact Person Details</h3>
                            <form style="height: 700px;">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label>Contact Person Name :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.nominee2?.name }}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>Designation :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.nominee2?.designation
                                                }}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>PAN Number :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.nominee2?.pan_number
                                                }}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>Email :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.nominee2?.email }}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>Office Phone :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.nominee2?.office_phone ?
                                                data?.nominee2?.office_phone :
                                                '-' }}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>Mobile Number:</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.nominee2?.mobile }}" />
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>Address :</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{data?.nominee2_address?.address}}" />
                                        </div>
                                    </div>


                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>Department :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.nominee2?.department
                                                }}" />
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label>Image :</label>
                                            <ng-container *ngIf="data?.nominee2?.media">
                                                <img [src]="getImage(data?.nominee2?.media,'profile2')" style="height: 70px;
                                                width:70px;">
                                            </ng-container>
                                            <ng-container *ngIf="!data?.nominee2?.media">
                                                <img src="../../../../../../assets/img/imageNotFound.png" style="height: 70px;
                                                    width:70px;">
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>Preferred Communication :</label>
                                            <ul>
                                                <li *ngFor="let item of
                                                    data?.nominee2?.preferred_communication
                                                    | jsonParse">
                                                    {{item}}</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                        <div class="form-group">
                                            <label>Interest Area :</label>
                                            <ul>
                                                <li *ngFor="let item of
                                                    data?.nominee2_interest">
                                                    {{item.interest_area}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- product details -->
                    <div class="col-12">
                        <div class="my-profile-box">
                            <h3>Details of Manufactured Products (Annually)</h3>
                            <div class="row mx-2 mt-2">
                                <ng-container *ngIf="product_details.length> 0">
                                    <ng-container *ngFor="let data of
                                        product_details">
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Product Description :</label>
                                                <input type="text" readonly class="form-control" value="{{
                                                    data?.product_description
                                                    }}" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12">
                                            <div class="form-group">
                                                <label>Product Quantity (Per
                                                    Annum) :</label>
                                                <input type="text" readonly class="form-control" value="{{
                                                    data?.product_quantity }}" />
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="product_details.length ==
                                    0">
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label>Product Description :</label>
                                            <input type="text" readonly class="form-control" value="-" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12">
                                        <div class="form-group">
                                            <label>Product Quantity (Per Annum)
                                                :</label>
                                            <input type="text" readonly class="form-control" value="-" />
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <!-- waste details -->
                    <div class="col-12">
                        <div class="my-profile-box">
                            <h3>Types of Hazardous Waste (Estimated Annual
                                Quantity)</h3>
                            <ng-container *ngFor="let data of waste_details">
                                <div class="row mx-2 mt-2">
                                    <div class="col-lg-3 col-md-12">
                                        <div class="form-group">
                                            <label>Schedule No :</label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.schedule_no }}" />
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-12">
                                        <div class="form-group">
                                            <label>Process No : </label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.waste_process_no }}" />
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-12">
                                        <div class="form-group">
                                            <label>Code : </label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.waste_sub_process_no }}" />
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-12">
                                        <div class="form-group">
                                            <label>Waste Type : </label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.waste_type }}" />
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-12">
                                        <div class="form-group">
                                            <label>Waste Quantity (MT per Annum) :
                                            </label>
                                            <input type="text" readonly class="form-control" value="{{
                                                data?.waste_quantity }}" />
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-4 col-md-12">
                                            <div class="form-group">
                                                <label>Waste Type :</label>
                                                <input type="text" readonly class="form-control" value="{{
                                                                                data?.waste_type }}" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-12">
                                            <div class="form-group">
                                                <label>Waste Category (HWM) :</label>
                                                <input type="text" readonly class="form-control" value="{{
                                                                                data?.waste_category }}" />
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-12">
                                            <div class="form-group">
                                                <label>Waste Quantity (Per Annum) :</label>
                                                <input type="text" readonly class="form-control" value="{{ 
                                                                                data?.waste_quantity }}" />
                                            </div>
                                        </div> -->
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <!-- extra information -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="my-profile-box">
                                <h3>Extra Information</h3>
                                <div class="row mx-2 mt-2">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="form-group">
                                            <label>Estimated or Actual
                                                Investment :</label>
                                            <span class="form-control">{{data?.hwm_details[0]?.investment
                                                ?
                                                data?.hwm_details[0]?.investment
                                                : ''}}</span>
                                            <!-- <input type="text" readonly class="form-control"
                                                    value="{{data?.hwm_details[0]?.investment }}" /> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12">
                                        <div class="form-group">
                                            <label>Expected Date for Disposal of
                                                HW :</label>
                                            <span class="form-control">{{data?.hwm_details[0]?.hw_disposal_expected_date
                                                ?
                                                (data?.hwm_details[0]?.hw_disposal_expected_date
                                                | date:'dd-MM-yyyy') : ''}}</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12">
                                        <div class="form-group">
                                            <label>Production Commencement Date
                                                :</label>
                                            <span
                                                class="form-control">{{data?.hwm_details[0]?.production_commencement_date
                                                ?
                                                (data?.hwm_details[0]?.production_commencement_date
                                                | date:'dd-MM-yyyy') : ''}}</span>
                                            <!-- <input type="text" readonly class="form-control"
                                                    value="{{data?.hwm_details[0]?.production_commencement_date | date:'dd-MM-yyyy'}}" /> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label>Incineration Facility
                                                Required :</label>
                                            <span
                                                class="form-control">{{data?.hwm_details[0]?.incineration_facility_required
                                                == 0 ? "No" : "Yes"}}</span>
                                            <!-- <ng-container
                                                    *ngIf="data?.hwm_details[0]?.incineration_facility_required == 0">
                                                    <input type="text" readonly class="form-control" value="no" />
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="data?.hwm_details[0]?.incineration_facility_required == 1">
                                                    <input type="text" readonly class="form-control" value="yes" />
                                                </ng-container> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label>The Production Has Not
                                                Started Yet :</label>
                                            <span
                                                class="form-control">{{data?.hwm_details[0]?.incineration_facility_required
                                                == 0 ? "No" : "Yes"}}</span>
                                            <!-- <ng-container
                                                    *ngIf="data?.hwm_details[0]?.incineration_facility_required == 0">
                                                    <input type="text" readonly class="form-control" value="no" />
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="data?.hwm_details[0]?.incineration_facility_required == 1">
                                                    <input type="text" readonly class="form-control" value="yes" />
                                                </ng-container> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>GST Certificate :</label>
                                            <a *ngIf="data?.hwm_details[0]?.gst_certificate" style=" font-size: 0.8rem;
                                                color: var(--mainColor);
                                                font-weight: 700;" class="btn
                                                btn-default"
                                                (click)="downLoadPdf(data?.hwm_details[0]?.gst_certificate,'gst')">
                                                {{data?.hwm_details[0]?.gst_certificate?.slice(0,15)}}
                                            </a>
                                            <span *ngIf="!data?.hwm_details[0]?.gst_certificate"
                                                class="form-control"></span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>Turnover :</label>
                                            <span class="form-control">{{data?.turn_over?.component
                                                ? data?.turn_over?.component :
                                                ''}}</span>
                                            <!-- <input type="text" readonly class="form-control"
                                                    value="{{data?.turn_over?.component}}" /> -->
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-12"
                                        *ngIf="data?.hwm_details[0]?.ca_certificate?.length>=1">
                                        <div class="form-group">
                                            <label>Please Upload CA Certificate
                                                If Turnover is Less Than 5
                                                Crores: :</label>
                                            <a style=" font-size: 0.8rem;
                                                color: var(--mainColor);
                                                font-weight: 700;" class="btn
                                                btn-default"
                                                (click)="downLoadPdf(data?.hwm_details[0]?.ca_certificate,'ca')">
                                                {{data?.hwm_details[0]?.ca_certificate?.slice(0,15)}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Status of Consents / Authorization -->
                    <div class="col-lg-12">
                        <div class="my-profile-box">
                            <h3>Status of Consents / Authorization</h3>
                            <div class="row mx-2 mt-2">
                                <!-- EC -->
                                <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                        <label>Environmental Clearance (EC)
                                            Applied :</label>
                                        <span class="form-control">{{consent_status
                                            ? consent_status[0]?.is_ec_applied :
                                            ''}}</span>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                        <label>Environmental Clearance (EC)
                                            Obtained:</label>
                                        <span class="form-control">{{consent_status
                                            ? consent_status[0]?.is_ec_obtained
                                            : ''}}</span>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6">
                                    <div class="form-group">
                                        <label>Environmental Clearance (EC) Hold
                                            Reason :</label>
                                        <span class="form-control">{{consent_status
                                            ? consent_status[0]?.ec_hold_reason
                                            : ''}}</span>
                                        <!-- <input type="text" readonly class="form-control"
                                            value="{{consent_status[0]?.ec_hold_reason}}" /> -->
                                    </div>
                                </div>

                                <!-- CTE -->
                                <div class="row">

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label>Consent To Establish (CTE)
                                                Applied :</label>
                                            <span class="form-control">{{consent_status
                                                ?
                                                consent_status[1]?.is_cte_applied
                                                : ''}}</span>
                                            <!-- <input type="text" readonly class="form-control"
                                                value="{{consent_status[1]?.is_cte_applied}}" /> -->
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label>Consent To Establish (CTE)
                                                Obtained
                                                :</label>

                                            <span class="form-control">{{consent_status
                                                ?
                                                consent_status[1]?.is_cte_obtained
                                                : ''}}</span>
                                            <!-- <input type="text" readonly class="form-control"
                                                value="{{consent_status[1]?.is_cte_obtained}}" /> -->
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label>Consent To Establish (CTE)
                                                Hold Reason :</label>
                                            <span class="form-control">{{consent_status
                                                ?
                                                consent_status[1]?.cte_hold_reason
                                                : ''}}</span>
                                            <!-- <input type="text" readonly class="form-control"
                                                value="{{ consent_status[1]?.cte_hold_reason}}" /> -->
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label>Consent To Establish (CTE)
                                                Document :</label>
                                            <a style=" font-size: 0.8rem;
                                                color: var(--mainColor);
                                                font-weight: 700;" class="btn
                                                btn-default" *ngIf="consent_status"
                                                (click)="downLoadPdf(consent_status[1]?.cte_document,'cte_doc')">
                                                {{consent_status[1]?.cte_document?.slice(0,15)}}
                                            </a>
                                            <span class="form-control" *ngIf="!consent_status">-</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- CTO -->
                                <div class="row">

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label>Consent To Operate (CTO)
                                                Applied
                                                :</label>
                                            <span class="form-control">{{consent_status
                                                ?
                                                consent_status[2]?.is_cto_applied
                                                : ''}}</span>

                                            <!-- <input type="text" readonly class="form-control"
                                                value="{{ consent_status[2]?.is_cto_applied }}" /> -->
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label>Consent To Operate (CTO)
                                                Obtained :</label>
                                            <span class="form-control">{{consent_status
                                                ?
                                                consent_status[2]?.is_cto_obtained
                                                : ''}}</span>
                                            <!-- <input type="text" readonly class="form-control"
                                                value="{{ consent_status[2]?.is_cto_obtained }}" /> -->
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label>Consent To Operate (CTO) Hold
                                                Reason
                                                :</label>
                                            <span class="form-control">{{consent_status
                                                ?
                                                consent_status[2]?.cto_hold_reason
                                                : ''}}</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-12" *ngIf="consent_status
                                        &&
                                        consent_status[2]?.cto_document?.length>=1">
                                        <div class="form-group">
                                            <label>Consent To Operate (CTO)
                                                Document
                                                :</label>
                                            <a style=" font-size: 0.8rem;
                                                color: var(--mainColor);
                                                font-weight: 700;" class="btn
                                                btn-default"
                                                (click)="downLoadPdf(consent_status[2]?.cto_document,'cto_doc')">
                                                {{consent_status[2]?.cto_document?.slice(0,15)}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- HWM -->
                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label>HWM Authorization Obtained :</label>
                                            <span class="form-control">{{consent_status?
                                                consent_status[3]?.is_hwm_auth_obtained
                                                : ''}}</span>
                                            <!-- <input type="text" readonly class="form-control"
                                                value="{{ consent_status[3]?.is_hwm_auth_obtained }}" /> -->
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label>HWM Authorization Applied :</label>
                                            <span class="form-control">{{
                                                consent_status ?
                                                consent_status[3]?.is_hwm_auth_applied
                                                : ''}}</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label>HWM Authorization Hold Reason
                                                :</label>
                                            <span class="form-control">{{consent_status
                                                ?
                                                consent_status[3]?.hwm_hold_reason
                                                : ''}}</span>
                                            <!-- <input type="text" readonly class="form-control"
                                                value="{{ consent_status[3]?.hwm_hold_reason }}" /> -->
                                        </div>
                                    </div>

                                    <div class="col-lg-12" *ngIf="consent_status
                                        &&
                                        consent_status[3]?.hwm_document?.length>=1">
                                        <div class="form-group">
                                            <label>HWM Authorization Document :</label>
                                            <a style=" font-size: 0.8rem;
                                                color: var(--mainColor);
                                                font-weight: 700;" class="btn
                                                btn-default"
                                                (click)="downLoadPdf(consent_status[3]?.hwm_document,'hwm_doc')">
                                                {{consent_status[3]?.hwm_document?.slice(0,15)}}
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-lg-4" *ngIf="consent_status
                                        &&
                                        consent_status[0]?.ec_document?.length>=1">
                                        <div class="form-group">
                                            <label>Environmental Clearance (EC)
                                                Document
                                                :</label>
                                            <a style=" font-size: 0.8rem;
                                                color: var(--mainColor);
                                                font-weight: 700;" class="btn
                                                btn-default"
                                                (click)="downLoadPdf(consent_status[0]?.ec_document,'ec_doc')">
                                                {{consent_status[0]?.ec_document?.slice(0,15)}}
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6">
                                        <div class="form-group">
                                            <label>Remarks
                                                :</label>
                                            <input type="text" readonly class="form-control" value="{{ data?.remark ?
                                                data?.remark : '-' }}" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Upload DOcuments -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="my-profile-box">
                                <h3>Upload Documents</h3>
                                <form>
                                    <div class="row">
                                        <!-- upload documents -->
                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Application on Company
                                                    Letterhead :</label>
                                                <a style=" font-size: 0.8rem;
                                                    color: var(--mainColor);
                                                    font-weight: 700;" class="btn btn-default"
                                                    (click)="downLoadPdf(data?.hwm_details[0]?.application,'app')">
                                                    {{data?.hwm_details[0]?.application?.slice(0,15)}}
                                                </a>
                                            </div>
                                        </div>


                                        <div class="col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label>Undertaking for New
                                                    Membership Document :</label>
                                                <a style=" font-size: 0.8rem;
                                                    color: var(--mainColor);
                                                    font-weight: 700;" class="btn btn-default"
                                                    (click)="downLoadPdf(data?.hwm_details[0]?.undertaking,'under')">
                                                    {{data?.hwm_details[0]?.undertaking?.slice(0,15)}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template pTemplate="footer">
                <p-button icon="pi pi-check" (click)="displayMaximizable=
                    false" label="Ok" styleClass="p-button-text"></p-button>
            </ng-template>
        </p-dialog>
    </div>
</section>
<!-- *********************************************************************************************************************** -->
<!-- payment section -->
<!-- *********************************************************************************************************************** -->
<div class="payment-section container" *ngIf="isShow">
    <div class="payment-totals row">
        <div class="col-12 adminssion-fee" *ngIf="addArray?.length> 0 && addArray != ''">
            <h3>Registration Fee</h3>
            <ul>
                <ng-container *ngFor="let data of addArray">
                    <li style="font-size: 15px; font-weight: 500;">{{data?.paid_for}}<span>₹{{(data.amount -
                            data.tax_amount)}}</span></li>
                    <li style="font-size: 15px; font-weight: 500;">Tax Amount<span>₹{{data?.tax_amount}}</span></li>
                    <li style="font-size: 15px; font-weight: 500;">{{data?.paid_for}}<span>₹{{data?.amount}}</span></li>
                </ng-container>
            </ul>
            <div class="add-listings-btn my-5">
                <button type="submit" class="button" (click)="proceedtoPayFunction(addArray, HwmDetailsId)">Proceed
                    to Pay</button>
            </div>
        </div>
        <div class="col-12 mt-5 trust-fee" *ngIf="trustArray?.length> 0">
            <h3>Trust Fee</h3>
            <ul>
                <ng-container *ngFor="let data of trustArray">
                    <li style="font-size: 15px; font-weight: 500;">
                        {{data.paid_for}}<span>₹{{(data.amount -
                            data.tax_amount)}}</span></li>
                    <li style="font-size: 15px; font-weight: 500;">
                        Tax Amount<span>₹{{data.tax_amount}}</span>
                    </li>
                    <li style="font-size: 15px; font-weight: 500;">
                        Total<span>₹{{data.amount}}</span>
                    </li>
                </ng-container>
            </ul>
            <div class="add-listings-btn mt-5">
                <button (click)="proceedtoPayFunction(trustArray, HwmDetailsId)" type="submit" class="button">Proceed
                    to Pay</button>
            </div>
        </div>
    </div>
</div>
<!-- ***************************************************************************************************************** -->
<!----------------------------------------------------------- payment dialog --------------------------------------------------------->
<!-- ***************************************************************************************************************** -->
<!-- <ng-template #body> -->
<p-dialog header="Transaction Details" [(visible)]="paymentDialog" [modal]="true" [style]="{ width: '62vw' }"
    [breakpoints]="{'850px' : '90vw'}" [draggable]="false" [resizable]="false" #verificationDialog id="backToTop">
    <form [formGroup]="paymentForm" (ngSubmit)="submit()" #payment="ngForm">
        <div class="add-listings-box">
            <h3>Registration Fee</h3>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Payment By<span class="required">*</span>:</label>
                        <p-dropdown [options]="paymentMode" optionValue="value" formControlName="paid_by"
                            placeholder="Select Option" optionLabel="label"
                            [styleClass]="(payment.submitted && paymentForm.get('paid_by')?.invalid) || paymentForm.get('paid_by')?.invalid && paymentForm.get('paid_by').touched ? 'error' : ''">
                        </p-dropdown>
                        <div *ngIf="payment.submitted && f.paid_by.errors?.required || f.paid_by.touched"
                            class="alert alert-danger">
                            <div *ngIf="f.paid_by.errors?.required">Payment By is Required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Cheque/Transaction No.</label>
                        <input class="form-control" type="text" class="form-control" placeholder="Cheque/Transaction No"
                            formControlName="cheque_no">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Transaction Date<span class="required">*</span>:</label>
                        <p-calendar formControlName="payment_date" showClear="true" dateFormat="dd/mm/yy"
                            placeholder="DD/MM/YYYY" required [showIcon]="true"></p-calendar>
                        <div *ngIf="payment.submitted &&
                            f.payment_date.errors?.required
                            ||
                            f.payment_date.touched" class="alert
                            alert-danger">
                            <div *ngIf="f.payment_date.errors?.required">
                                Transaction Date is Required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Cheque Date</label>
                        <p-calendar formControlName="cheque_date" showClear="true" dateFormat="dd/mm/yy"
                            placeholder="DD/MM/YYYY" [showIcon]="true"></p-calendar>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Bank Name:</label>
                        <input class="form-control" type="text" class="form-control" placeholder="Bank Name"
                            formControlName="bank_name">
                        <!-- <div *ngIf="payment.submitted &&
                                f.bank_name.errors?.required ||
                                f.bank_name.touched" class="alert alert-danger">
                            <div *ngIf="f.bank_name.errors?.required">
                                Bank Name is Required
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <h3>Trust Fee</h3>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Payment By<span class="required">*</span>:</label>
                        <p-dropdown [options]="paymentMode" optionValue="value" formControlName="trust_paid_by"
                            placeholder="Select Option" optionLabel="label"
                            [styleClass]="(payment.submitted && paymentForm.get('trust_paid_by')?.invalid) || paymentForm.get('trust_paid_by')?.invalid && paymentForm.get('trust_paid_by').touched ? 'error' : ''">
                        </p-dropdown>
                        <div *ngIf="payment.submitted && f.trust_paid_by.errors?.required || f.trust_paid_by.touched"
                            class="alert alert-danger">
                            <div *ngIf="f.trust_paid_by.errors?.required">Payment By is Required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Cheque/Transaction No.</label>
                        <input class="form-control" type="text" class="form-control" placeholder="Cheque/Transaction No"
                            formControlName="trust_cheque_no">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group trust">
                        <label>Transaction Date<span class="required">*</span>:</label>
                        <p-calendar class="tdate" formControlName="trust_payment_date" showClear="true"
                            dateFormat="dd/mm/yy" placeholder="DD/MM/YYYY" required [showIcon]="true"></p-calendar>
                        <div *ngIf="payment.submitted &&
                            f.trust_payment_date.errors?.required
                            ||
                            f.trust_payment_date.touched" class="alert
                            alert-danger">
                            <div *ngIf="f.trust_payment_date.errors?.required">
                                Transaction Date is Required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Cheque Date</label>
                        <p-calendar class="tdate" formControlName="trust_cheque_date" showClear="true"
                            dateFormat="dd/mm/yy" placeholder="DD/MM/YYYY" [showIcon]="true"></p-calendar>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Bank Name:</label>
                        <input class="form-control" type="text" class="form-control" placeholder="Bank Name"
                            formControlName="trust_bank_name">
                        <!-- <div *ngIf="payment.submitted &&
                                    f.trust_bank_name.errors?.required
                                    ||
                                    f.trust_bank_name.touched" class="alert
                                    alert-danger">
                            <div *ngIf="f.trust_bank_name.errors?.required">
                                Bank Name is Required
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="add-listings-btn">
                    <button type="submit" class="submit_btn">Submit</button>
                </div>
            </div>
        </div>
    </form>
</p-dialog>

<!-- ***************************************************************************************************************** -->
<!----------------------------------------------------------- verification dialog --------------------------------------------------------->
<!-- ***************************************************************************************************************** -->
<!-- <ng-template #body> -->
<p-dialog header="Verification" [(visible)]="VerificationDialog" [modal]="true" [style]="{ width: '35vw' }"
    [breakpoints]="{'850px' : '90vw'}" [draggable]="false" [resizable]="false">
    <table>
        <thead>
            <tr>
                <th>Verified by</th>
                <th>Status</th>
                <th>Remark/Reason</th>
            </tr>
        </thead>
        <tbody>
            <ng-container>
                <tr *ngIf="memberVerficationDetails?.vid_1">
                    <td>{{userNames[0]}}</td>
                    <td>{{ memberVerficationDetails?.verification1 == 1 ? 'Verified' :
                        (memberVerficationDetails?.verification1 == 0 ? 'Discrepant' : '') }}</td>
                    <td>{{ memberVerficationDetails?.vremarks_1 }}</td>
                </tr>
                <tr *ngIf="memberVerficationDetails?.vid_2">
                    <td>{{ userNames[1] }}</td>
                    <td>{{ memberVerficationDetails?.verification2 == 1 ? 'Verified' :
                        (memberVerficationDetails?.verification2 == 0 ? 'Discrepant' : '') }}</td>
                    <td>{{ memberVerficationDetails?.vremarks_2 }}</td>
                </tr>
                <tr *ngIf="memberVerficationDetails?.vid_3">
                    <td>{{ userNames[2] }}</td>
                    <td>{{ memberVerficationDetails?.verification3 == 1 ? 'Verified' :
                        (memberVerficationDetails?.verification3 == 0 ? 'Discrepant' : '') }}</td>
                    <td>{{ memberVerficationDetails?.vremarks_2 }}</td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <ng-container *ngIf="localStorage.role == 'President'">
        <div class="add-listings-btn">
            <button class="button success-btn mr-2" (click)="approvalProcess(1)">Approve</button>
            <button class="button reject-btn mr-2" (click)="approvalProcess(0)">Reject</button>
        </div>
    </ng-container>
    <ng-container *ngIf="localStorage.role != 'President'">
        <form [formGroup]="verificationForm" (ngSubmit)="verificationFormFunction(hwmverification)"
            #hwmverification="ngForm">
            <div class="upload-dialog">
                <div class="form-group">
                    <label class="mb-3" for="">
                        Remark<span class="required">*</span></label>
                    <textarea type="text" placeholder="Remark/Reason" formControlName="remark" [class.is-invalid]="(hwmverification.submitted &&
            verificationForm.get('remark')?.invalid) ||
            verificationForm.get('remark')?.invalid &&
            verificationForm.get('remark').touched" class="form-control" required>
            </textarea>
                    <div class="invalid-feedback" style="margin-top: 1.25rem;">Remark is Required
                    </div>
                </div>
                <br>
                <div class="add-listings-btn">
                    <button class="button success-btn mr-2" (click)="document_status = 'Verified'">Verify</button>
                    <button class="button reject-btn mr-2"
                        (click)="document_status = 'Discrepancy'">Discrepancy</button>
                </div>
                <!-- <button pButton label="Save" class="p-button-outlined my-3"></button> -->
            </div>
        </form>
    </ng-container>
</p-dialog>
<!-- ----------------------------------------------------------------------- -->
<!--                                   Drafted Pollution Letter                                   -->
<!-- ----------------------------------------------------------------------- -->
<p-dialog header="Pollution Control Board Letter" [(visible)]="pollutionLetterDialog" [modal]="true"
    [style]="{width: '70vw'}" [breakpoints]="{'850px' : '90vw'}" [draggable]="false" [resizable]="false">
    <ng-container *ngFor="let item of hwmMemberData">
        <div style=" display: flex;align-items:center;justify-content:space-between;">
            <div>
                <h5 style="font-size: 16px;">UCCI/HWMF-{{item?.membership_no ?? '-'}}/2023-24 </h5>
            </div>
            <div>
                <h5 style="font-size: 16px;">{{item.created_at | date:'MMMM d, y'}}</h5>
            </div>
        </div>
        <div class="address">
            <p>The Member Secretary,</p>
            <p>Rajasthan Pollution Control Board</p>
            <p>4-Institutional Area</p>
            <p>Jhalana Doongri</p>
            <p>Jaipur</p>
        </div>

        <p>Dear Sir,</p>

        <p>Grant of membership of Hazardous Waste Management Facility (CTDF) at Udaipur</p>
        <p>(MEMBERSHIP NO. {{item?.membership_no ?? '-'}})</p>

        <p>We are to inform you that M/s <b>
                {{item?.company_name | titlecase}}, {{item?.company_address[0]?.address}},
                {{item?.company_address[0]?.pin_code}}
            </b>
            have completed the necessary
            formalities to become
            a
            member of the common Hazardous Waste Treatment Facility at Udaipur.</p>

        <table>
            <thead>
                <tr>
                    <th>S.No</th>
                    <th>Type of Waste</th>
                    <th>Sch.</th>
                    <th>Process No.</th>
                    <th>Cat.</th>
                    <th>Qty / Annum (MT Per Annum)</th>
                </tr>
            </thead>
            <tbody *ngFor="let details of waste_details; let i=index">
                <tr>
                    <td>{{i + 1}}</td>
                    <td>{{details?.waste_type}}</td>
                    <td>{{details?.schedule_no}}</td>
                    <td>{{details?.waste_process_no}}</td>
                    <td>{{details?.waste_sub_process_no}}</td>
                    <td>{{details?.waste_quantity}}</td>
                </tr>
            </tbody>
        </table>

        <p>The above-mentioned hazardous waste will be acceptable to our site at Udaipur.</p>

        <p>We request you to kindly consider M/s <b>
                {{item?.company_name}}, {{item?.company_address[0]?.address}},
                {{item?.company_address[0]?.pin_code}}
            </b>
            as a member of the hazardous
            waste disposal facility and do the needful.</p>

        <p>Thanking you,</p>
        <p>Yours faithfully,</p>
        <strong>For Udaipur Chamber of Commerce &amp; Industry</strong>

        <div class=" signature">
            <strong>Sanjaya Singhal</strong>
            <br>
            <strong>President</strong>
        </div>

        <div class="copy">
            <p>Copy to:</p>
            <ol>
                <li>The Regional Officer, Rajasthan State Pollution Control Board, {{item?.regional_office}}
                </li>
                <li>M/s <b>
                        {{item?.company_name}}, {{item?.company_address[0]?.address}} <br> District :
                        {{item?.company_address[0]?.district}} - {{item?.company_address[0]?.pin_code}},
                        {{item?.company_address[0]?.state}} - Your registration No. is {{item?.membership_no ??
                        '-'}}
                    </b>
                    which
                    you may please
                    quote
                    while communicating with us in the future. We are also enclosing the copies of our Circulars
                    for
                    your
                    information. Kindly note that you need to renew your HWM membership annually.</li>
                <li>M/s Re Sustainability Limited (Formerly known as Ramky Enviro Engineers Limited), Udaipur –
                    Kindly
                    accept the above category and quantity of hazardous waste from M/s <b>
                        {{item?.company_name}},
                        {{item?.company_address[0]?.city}}.
                    </b>
                </li>
            </ol>
        </div>
    </ng-container>

    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="pollutionLetterDialog = false" label="Ok"
            styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
<ng-container *ngIf="successState">
    <app-success [statusMsg]="message"></app-success>
</ng-container>
<ng-container *ngIf="payStatus">
    <app-payment-form [RecivedData]="paymentResponse"></app-payment-form>
</ng-container>
<!-- <app-copyrights></app-copyrights>
</div> -->