import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { InterestMasterService } from 'src/app/services/interest-master.service';
import { NatureOfBusinessMasterService } from 'src/app/services/nature-of-business-master.service';
import { PaymentService } from 'src/app/services/payment.service';
import { utility } from 'src/app/services/utility';
import { environment } from 'src/environments/environment';
declare var Razorpay: any;

@Component({
  selector: 'app-hwm-member-list',
  templateUrl: './hwm-member-list.component.html',
  styleUrls: ['./hwm-member-list.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class HwmMemberListComponent implements OnInit {
  @ViewChild('nameFilter') dataTable: Table;
  @ViewChild('hwmverification') hwmverification: NgForm;
  baseUrl = environment.regularMemberListURL;
  ucciBaseUrl = environment.ucci_base_url;
  loading: boolean = false;
  hwmMemberList: any = [];
  inprogress: string = 'In Progress';
  breadcrumb: any[];
  isShow: boolean = false;
  displayMaximizable: boolean = false;
  localStorage: any;
  email: any = '';
  company_name: any = '';
  filterVal: string;
  tooltipText: string = environment.resetTooltip;
  dtDataLength: any;
  hwmDetails: any = [];
  trustArray: any = [];
  addArray: any = [];
  HWMDetailsId: any = [];
  consent_status: any = [];
  waste_details: any = [];
  product_details: any = [];
  cols: { field: string; header: string }[];
  exportColumns: { title: string; dataKey: string }[];
  pdfData: any[];
  multipleFields: any = [{ key: null, value: null, dropdownKey: [] }];
  objectKeysArray: any = [];
  @Input() hideDataForAnotherComponent: boolean = true;
  @Input() hwmMemberListData: boolean = false;


// In your class, define an array to store filter values
filterValues: any = [];


  keyNames: any = ['turn_over', 'Nature of Business'];
  colFilter = [
    { field: 'S. No.' },
    { field: 'action' },
    { field: 'payment' },
    { field: 'status' },
    { field: 'company_name' },
    { field: 'member_since'},
    { field: 'membership_no' },
    { field: 'email' },
    { field: 'date' },
    { field: 'renewal_status' },
    { field: 'invoice' },
    { field: 'checklist' },
    { field: 'letter' },
    { field: 'notesheet' },
  ];

  status = [
    { label: 'Verified', value: 'Verified' },
    { label: 'Rejected', value: 'Rejected' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Approved', value: 'approved' },
    { label: 'In Progress', value: 'in progress' },
    { label: 'Terminated', value: 'Terminated' },
    { label: 'Provisionally Terminated', value: 'Provisionally Terminated' },
  ];
  renewal_status = [
    { label: 'Pending', value: 'pending' },
    { label: 'Renewed', value: 'renewed' },
  ];
  excelData: any[];
  filteredXLSData: any[];
  filteredPDFData: any[];
  turnOverData: any = [];
  areaOfInterest: any = [];
  natureofBusinessListData: any = [];
  roleObject: any = environment.roles;
  total: any = 0;
  AdmissionFeeTotal: number = 0;
  TrustFeeTotal: number = 0;
  payStatus: boolean = false;
  slug: any;
  successState: boolean;
  message: any;
  paymentResponse: { formState: boolean; merchantRequest: string, string: any };
  rowId: any;
  paymentFor: any;
  userPermission: any;
  updatePermission: boolean = false;
  deletePermission: boolean = false;
  viewPermission: boolean = false;
  approvalPermission: boolean = false
  paymentDialog: boolean = false;
  showHwmListing: boolean = true;
  showRenewalListing: boolean = true;
  memberVerficationDetails:any
  paymentMode: any = [
    { label: 'Cash', value: 'Cash' },
    { label: 'Cheque', value: 'Cheque' },
    { label: 'RTGS', value: 'RTGS' },
    { label: 'NEFT', value: 'NEFT' },
    { label: 'IMPS', value: 'IMPS' },
    { label: 'Payment Gateway', value: 'Payment Gateway' },
  ];
  renewalListing: any = [];
  VerificationDialog: boolean = false;
  pollutionLetterDialog: boolean = false;
  draftData: any = [];
  response: any = [];
  storageData: any;
  HwmDetailsId: any;
  userVerified: any;
  verficationStatus: any;
  userNames: string[] = [];
  enableAddButton: boolean=false;
  constructor(
    private commonFunction: CommonClass,
    private __apiservice: ApiService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private paymentservice: PaymentService,
    public apiService: ApiService,
    private spinner: NgxSpinnerService,
    public interestMasterService: InterestMasterService,
    private natureofBusiness: NatureOfBusinessMasterService,
    private activated: ActivatedRoute,
    private router: Router,
    private utility: utility,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.localStorage = this.commonFunction.getLocalStorage();
    this.storageData = JSON.parse(localStorage.getItem('access_token'));
    this.spinner.show();
    this.slug = this.activated.snapshot.params;
    this.getHwmMemberList();
    this.checkPermissions();
    if (this.slug?.id && this.slug?.status && this.slug?.hwm_id && this.slug?.order_id) {
      this.apiService.getMemberById(this.slug?.id).subscribe((res: any) => {
        this.hwmMemberData.push(res.data)
        // this.hwmMemberData = res?.data;
        if (res.data?.hwm_admission?.payment_status == 'Payment Success' && res.data?.hwm_trust?.payment_status != 'Payment Success') {
          this.message = {
            form: 'hwm-edit',
            message: res?.message,
          };
          this.successState = true;
        }
        else if (res.data?.hwm_admission?.payment_status == 'Payment Success' && res.data?.hwm_trust?.payment_status == 'Payment Success') {
          let statusDetail = {
            status: this.slug?.status == 'Success' ? 'Payment Success' : 'Payment Failed',
            id: this.slug?.hwm_id
          }
          this.paymentservice.hwmPayment(this.slug?.order_id, this.slug?.id, statusDetail).subscribe((res: any) => {
            if (res?.code == 200) {
              this.messageService.add({
                severity: 'success',
                detail: res?.message,
              });
              // this.getHwmMemberList()
            }
            this.message = {
              form: 'hwm-edit',
              message: res?.message,
            };
            this.successState = true;
          });
        }
      });
    }
    else {
      let memberRole = [this.roleObject.hwmOwner, this.roleObject.hwmContact];
      this.breadcrumb = [
        {
          title: memberRole.includes(this.localStorage.role)
            ? 'My Membership'
            : 'HWM Member',
          subTitle: 'Dashboard',
        },
      ];
      this.loading = true;
      if (this.hideDataForAnotherComponent) {
        this.natureOfBusinessList();
      }
      this.cols = [
        { field: 'sr_no', header: 'Sr No' },
        { field: 'company_name', header: 'Company Name' },
        {field: 'member_since', header : 'Member Since'},
        { field: 'invoice_payment', header: 'Invoice Payment' },
        { field: 'email', header: 'Email' },
        { field: 'office_telephone', header: 'Office Telephone' },
        { field: 'gst_number', header: 'GST number' },
        { field: 'nominee1Name', header: 'Owner Name' },
        { field: 'nominee2Name', header: 'Contact Person Name' },
      ];
      this.exportColumns = this.cols.map((col) => ({
        title: col.header,
        dataKey: col.field,
      }));

      let roles = ['Admin', 'UCCI Staff', 'CEO', 'president', 'Guest'];
      if (!roles.includes(this.localStorage.role)) {
        this.colFilter = [
          { field: 'S. No.' },
          { field: 'action' },
          { field: 'payment' },
          { field: 'status' },
          { field: 'company_name'},
          {field: 'member_since'},
          { field: 'membership_no' },
          { field: 'amount' },
          { field: 'date' },
          // { field: 'certificate' },
          { field: 'email' },
          { field: 'invoice' },
        ];
      }

      if ((this.localStorage.role == 'HWM Owner') || (this.localStorage.role == 'HWM Contact Person')) {
        this.colFilter = [
          { field: 'S. No.' },
          { field: 'action' },
          { field: 'payment' },
          { field: 'status' },
          { field: 'company_name' },
          {field: 'member_since'},

          // { field: 'renewal_status' },
          { field: 'membership_no' },
          { field: 'amount' },
          { field: 'date' },
          // { field: 'certificate' },
          // { field: 'checklist' },/
          { field: 'email' },
        ];
        this.getRenewalList();
        this.showTable('hwm_listing')
      }
      if (this.hideDataForAnotherComponent) {
        this.getTurnoverData();
        this.getNature();
      }
    }
    if (this.slug.id && this.slug.status && this.slug.order_id) {
      let statusDetail = {
        status: this.slug?.status == 'Success' ? 'Payment Success' : 'Payment Failed',
      };
      if (this.slug.module == 'renew') {
        this.apiService.renew(this.slug?.order_id, this.slug?.member_id, this.slug?.id, statusDetail).subscribe(
          (res: any) => {

            this.messageService.add({
              severity: 'success',
              detail: res?.message,
            });
            setTimeout(() => {
              this.router.navigateByUrl('/user-panel/dashboard');
            }, 1500);
          }
        );
      }
    }
    if ((this.localStorage.role == 'Admin') || (this.localStorage.role == 'UCCI Staff') || (this.localStorage.role == 'President')) {
      this.colFilter = [
        { field: 'S. No.' },
        { field: 'action' },
        { field: 'status' },
        { field: 'company_name' },
        {field:  'member_since'},
        { field: 'membership_no' },
        { field: 'email' },
        { field: 'date' },
        { field: 'renewal_status' },
        { field: 'payment' },
        { field: 'checklist' },
        { field: 'pcb' },
        { field: 'notesheet ' },
      ];

    }
  }
  // turnover data list
  getTurnoverData() {
    this.apiService.getTurnoverData().subscribe((res: any) => {
      res?.data?.forEach((element) => {
        let object = {
          label: element?.component,
          value: element?.component,
        };
        this.turnOverData.push(object);
      });
    });
  }
  // get nature of business list
  natureOfBusinessList() {
    this.natureofBusiness.getNatureofBusinessList().subscribe((res: any) => {
      res?.data?.forEach((element) => {
        let object = {
          label: element?.name,
          value: element?.name,
        };
        this.natureofBusinessListData.push(object);
      });
    });
  }
  // nature of business list
  getNature() {
    this.interestMasterService.getInterestArea().subscribe((res: any) => {
      res?.data?.forEach((element) => {
        let object = {
          label: element?.interest_area,
          value: element?.interest_area,
        };
        this.areaOfInterest.push(object);
      });
    });
  }
  // hwm member list
  getHwmMemberList() {
    this.__apiservice.getHwmMemberList().subscribe(
      (res: any) => {
        this.response = res;
        this.hwmMemberList = res?.data;
        console.log(this.hwmMemberList)

        // if (this.hwmMemberList.length > 0) {
        //   this.hwmMemberList.forEach((member) => {
        //     if (member.hwm_verification_details && member.hwm_verification_details.length > 0) {
        //       const verified = member.hwm_verification_details[0].status_and_remark;
        //               const jsonString = verified.slice(1, -1);
        //       const validJSONString = jsonString.replace(/\\"/g, '"');
        //       const jsonObject = JSON.parse(validJSONString);
        //       console.log(jsonObject);
        //       debugger
        //       if (jsonObject.length >= 3) {
        //         this.__apiservice
        //           .hwmMemberApproval(member.id, 3)
        //           .subscribe((res: any) => {
        //              console.log(member.id ,"is verified")
        //           });
        //       }
        //     }
        //   });
        // }
        



        this.draftData.push(res?.member_data[0]?.member_data_json);

        this.dtDataLength = this.hwmMemberList?.length;
        this.loading = false;
        this.hwmMemberList?.map((data: any) => {
          this.hwmDetails.push(data?.payment);
          this.AdmissionFeeTotal = parseFloat(data?.hwm_admission?.amount);
          this.TrustFeeTotal = parseFloat(data?.hwm_trust?.amount);
          this.total = (this.AdmissionFeeTotal) + this.TrustFeeTotal
        });
        const validKey = [
          'company_name',
          'registration_number',
          'email',
          'website',
          'capital_investment',
          'gst_number',
          'status',
          'turn_over',
          'cin_number',
        ];
        for (const key in this.hwmMemberList[0]) {
          if (validKey.includes(key)) {
            this.objectKeysArray.push({ name: key });
          }
        }
        this.objectKeysArray.push({ name: 'Area of Interest' });
        this.objectKeysArray.push({ name: 'Nature of Business' });
        this.hwmMemberList.forEach((response) => {
          response.nominee_Interest =
            JSON.stringify(response?.nominee1_interest ?? '') +
            JSON.stringify(response?.nominee2_interest ?? '');
        });
        this.verifiedMembers;
      },
      (error: any) => {
        this.loading = false;
      }
    );
  }
  get verifiedMembers(): any[] {
    // Filter the regularMemberList to show only verified members
    return this.hwmMemberList.filter(member => member.status == 'In Progress');
  }
  // to approve and reject hwm member
  document_status: string = '';
  userId: any;
  memberId: any;
  memberDetails: any = [];
  statusandremark: any;
  approveMemberById(value?: any, id?: any, boolean?: any, memberData?: any, status?: any) {
    this.userNames = [];
    this.memberId = id;
    debugger
    this.verficationStatus = status;
    this.memberVerficationDetails = memberData.hwm_verification_details[0];
    if (memberData.hwm_verification_details.length !== 0) {
        const promises = [];
        for (let i = 1; i <= 3; i++) {
            const vidKey = 'vid_' + i;
            const vid = memberData.hwm_verification_details[0][vidKey];

            // Check if vid is not null
            if (vid !== null) {
                // Call getUserbyId and push the result into userNames array
                promises.push(
                    new Promise((resolve, reject) => {
                        this.apiService.getUserbyId(vid).subscribe(
                            (res: any) => {
                                resolve(res?.name);
                            },
                            error => {
                                reject(error);
                            }
                        );
                    })
                );
            }
        }

        Promise.all(promises)
            .then(names => {
                this.userNames.push(...names.filter(name => name)); // Push only non-null names
                
                console.log(this.userNames); // Log user names here if needed

                // Perform further actions here
                // if (value == 'hwm') {
                //     // Your logic for hwm approval/rejection
                // } else if (value == 'verification') {
                    this.memberDetails = [];
                    this.VerificationDialog = true;
                    if (memberData.hwm_verification_details.length > 0) {
                        memberData.hwm_verification_details.forEach(res => {
                            this.statusandremark = res.status_and_remark;
                            this.memberDetails = JSON.parse(JSON.parse(res.status_and_remark));
                        });
                    }
                // }
            })
            .catch(error => {
                console.error('Error fetching user names:', error);
            });
    } else {
        console.log(this.userNames); // Log user names here if needed

        // Perform further actions here
        // if (value == 'hwm') {
           
        // } else if (value == 'verification') {
            this.memberDetails = [];
            this.VerificationDialog = true;
            if (memberData.hwm_verification_details.length > 0) {
                memberData.hwm_verification_details.forEach(res => {
                    this.statusandremark = res.status_and_remark;
                    this.memberDetails = JSON.parse(JSON.parse(res.status_and_remark));
                });
            }
        // }
    }
}

  // Approval by president
  approvalProcess(boolean: number) {
    let body: any = {
      approval: boolean,
    };
    this.__apiservice
      .hwmMemberApproval(this.memberId, body)
      .subscribe((res: any) => {
        this.messageService.add({
          severity: 'success',
          detail: res?.message,
        });
        this.VerificationDialog = false;
        this.getHwmMemberList();
      });
  }
  // form control
  data: any;
  verificationForm = new FormGroup({ remark: new FormControl('', [Validators.required]) });
  verificationFormFunction(hwmverification) {


    if (localStorage.getItem('access_token')) {
      var userName: any = JSON.parse(localStorage.getItem('access_token')).username;
      this.userId = JSON.parse(localStorage.getItem('access_token')).userId;
    }
    if (this.verificationForm.valid) {
      let formData = new FormData();
      this.data = this.verificationForm.value;
      if (this.document_status == 'Verified') {
        // const DetailsData = [
        //   {
        //     status: this.document_status,
        //     remark: this.verificationForm.value.remark,
        //     user_id: this.userId,
        //     staff_name: userName,
        //   }
        // ];
        // const combinedData = [
        //   ...DetailsData,
        //   ...this.memberDetails
        // ];
        // const hwm_verification_json = JSON.stringify(combinedData);
        // formData.append('remark', this.data?.remark);
        // formData.append('status', this.document_status);
        // formData.append('hwm_verification_json', hwm_verification_json);
        if(this.verficationStatus == 'In Progress' && this.memberVerficationDetails == null || this.memberVerficationDetails.vid_1 == null){
          formData.append('vid_1',this.userId);
          formData.append('verification1','1');
          formData.append('vremarks_1',this.verificationForm.value.remark);
        }else if(this.memberVerficationDetails?.verification2 == null && this.memberVerficationDetails?.verification1 == 1){
          formData.append('vid_2',this.userId);
          formData.append('verification2','1');
          formData.append('vremarks_2',this.verificationForm.value.remark);
        }else if(this.memberVerficationDetails?.verification3 == null && this.memberVerficationDetails?.verification2 == 1){
          formData.append('vid_3',this.userId);
          formData.append('verification3','1');
          formData.append('vremarks_3',this.verificationForm.value.remark);
        }
      } else if (this.document_status == 'Discrepancy') {
        // const DetailsData = [
        //   {
        //     status: this.document_status,
        //     remark: this.verificationForm.value.remark,
        //     user_id: this.userId,
        //     staff_name: userName,
        //   }
        // ];
        // const combinedData = [
        //   ...DetailsData,
        //   ...this.memberDetails
        // ];
        // const hwm_verification_json = JSON.stringify(combinedData);
        // formData.append('remark', this.data?.remark);
        // formData.append('status', this.document_status);
        // formData.append('hwm_verification_json', hwm_verification_json);
        if(this.verficationStatus == 'In Progress' && this.memberVerficationDetails == null){
          formData.append('vid_1',this.userId);
          formData.append('verification1','0');
          formData.append('vremarks_1',this.verificationForm.value.remark);
        }else if(this.memberVerficationDetails?.verification2 == null && this.memberVerficationDetails?.verification1 == 1){
          formData.append('vid_2',this.userId);
          formData.append('verification2','0');
          formData.append('vremarks_2',this.verificationForm.value.remark);
        }else if(this.memberVerficationDetails?.verification3 == null && this.memberVerficationDetails?.verification2 == 1){
          formData.append('vid_3',this.userId);
          formData.append('verification3','0');
          formData.append('vremarks_3',this.verificationForm.value.remark);
        }
      }
    

      this.apiService.verifyHwmProcess(this.memberId, formData)
        .subscribe((res: any) => {
          if (res?.code == 200) {
            hwmverification.resetForm();
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
            setTimeout(() => {
              this.VerificationDialog = false;
              hwmverification.resetForm();
            }, 1000);

            if(this.memberVerficationDetails?.verification1 == 1 && this.memberVerficationDetails?.verification2 == 1){
              let body: any = {
                approval: 3,
              };
              this.__apiservice
              .hwmMemberApproval(this.memberId, body)
              .subscribe((res: any) => {
                console.log("member is verified");
                this.getHwmMemberList();
              });
            }
            else{
              this.getHwmMemberList();
            }
            
          }
        });



      this.userVerified = this.hwmMemberList.filter((event) => {
        return this.memberId == event.id
      })
      console.log(this.userVerified);
      // if (this.userVerified.length > 0) {
      //   const verified = this.userVerified[0].hwm_verification_details[0].status_and_remark;
      //   const jsonString = verified.slice(1, -1);
      //   const validJSONString = jsonString.replace(/\\"/g, '"');

      //   const jsonObject = JSON.parse(validJSONString);

      //   console.log(jsonObject);
      //   if(jsonObject.length == 2 ){
      //     let body: any = {
      //       approval: 3,
      //     };
      //     this.__apiservice
      //     .hwmMemberApproval(this.memberId, body)
      //     .subscribe((res: any) => {
      //       console.log("member is verified");
      //     });
      //   }
      // }
    }
  }
  reset(nameFilter) {
    nameFilter.reset();
    this.filterVal = '';
    this.email = '';
    this.company_name = '';

    if (nameFilter.filters['company_name'])
      nameFilter.filters['company_name']['value'] = '';
    if (nameFilter.filters['email']) nameFilter.filters['email']['value'] = '';
    this.multipleFields = [{ key: '', value: '', dropdownKey: '' }];
    for (let i = 0; i < this.multipleFields.length; i++) {
      let name = this.multipleFields[i]?.key?.name;
      if (this.dataTable.filters[name]) {
        this.dataTable.filters[name]['value'] = '';
      }
    }

    this.multipleFields = [{ key: '', value: '', dropdownKey: '' }];
  }
  // view button function
  details: any = [];
  hwmMemberData: any = [];
  showMaximizableDialog(id?: any, type?: any) {
    if (type == 'view') {
      this.displayMaximizable = true;
    } else if (type == 'letter') {
      this.pollutionLetterDialog = true;
    }
    this.hwmMemberData = [];
    this.apiService.getMemberById(id?.id).subscribe((res: any) => {
      this.hwmMemberData.push(res.data)

      // this.hwmMemberData = res?.data;
      setTimeout(() => {
        this.showData();
      }, 1000);
    });

  }
  showData() {
    if (this.hwmMemberData) {
      if (this.hwmMemberData[0]?.hwm_details[0]?.ec_consents_status) {
        this.consent_status = JSON.parse(this.hwmMemberData[0]?.hwm_details[0]?.ec_consents_status);
      }

      if (this.hwmMemberData[0]?.hwm_details[0]?.hw_details) {
        this.waste_details = JSON.parse(this.hwmMemberData[0]?.hwm_details[0]?.hw_details);
      }

      if (this.hwmMemberData[0]?.hwm_details[0]?.products_details) {
        this.product_details = JSON.parse(this.hwmMemberData[0]?.hwm_details[0]?.products_details);
      }
    }

  }
  // image path url
  getImage(path?: string, end_point?: any) {
    let returnValue: any;
    returnValue = this.baseUrl;
    if (end_point == 'logo') {
      returnValue = returnValue + end_point + '/' + path;
    } else if (end_point == 'profile1') {
      returnValue = returnValue + end_point + '/' + path;
    } else if (end_point == 'profile2') {
      returnValue = returnValue + end_point + '/' + path;
    }
  }
  // ******************************************Download PDF Process*****************************************************
  // download pdf file process
  addLink(value?: any, string?: any) {
    if (string == 'ec_doc') {
      return environment.ecDocBaseURL + value;
    } else if (string == 'cte_doc') {
      return environment.cteDocBaseURL + value;
    } else if (string == 'cto_doc') {
      return environment.ctoDocBaseURL + value;
    } else if (string == 'hwm_doc') {
      return environment.hwmDocBaseURL + value;
    } else if (string == 'app') {
      return environment.appDocBaseURL + value;
    } else if (string == 'gst') {
      return environment.gstCertificateURL + value;
    } else if (string == 'ca') {
      return environment.caCertificateURL + value;
    } else if (string == 'under') {
      return environment.utDocBaseURL + value;
    } else if (string == 'invoice') {
      return environment.DocReceiptBaseURL + value;
    }
  }
  downLoadPdf(data: any, string?: any) {
    let value = this.addLink(data, string);
    var anchor = document.createElement('a');
    anchor.download = value;
    anchor.href = value;
    anchor.target = '_blank';
    anchor.click();
  }
  // end download pdf file process
  // ***********************************************************payment process razorpay******************************************************
  proceedtoPayFunction(paymentArray?: any, hwmId?: number) {
    this.HwmDetailsId = hwmId;
    paymentArray.map((resp: any) => {
      this.paymentFor = resp?.paid_for
      let payload = {
        payment_id: resp?.id,
        id: this.rowId,
        url: environment.frontURL + '/user-panel/hwm-member-list/' + this.rowId + '/' + this.HwmDetailsId,
        module: 'HWM Member Form',
        payment_for: resp?.paid_for == 'HWM Trust' ? 'Trust' : ''
      }
      this.apiService.paymentOnListing(paymentArray.payment_id, payload).subscribe((res: any) => {
        let gatewayResponse: string = res.gatewayResponse;
        this.payStatus = true;
        this.paymentResponse = {
          formState: true,
          merchantRequest: gatewayResponse,
          string: this.paymentFor
        };
      });
    })
    // if(value == 'admission') {
    // }
    // if (value == 'admission') {
    //   let options = {
    //     key: environment.rezorpay_key,
    //     amount: this.addArray[0].amount,
    //     name: 'UCCI',
    //     image: environment.rezorpay_logo,
    //     order_id: this.addArray[0].order_id,
    //     handler: function (response: any) {
    //       var event = new CustomEvent('payment.success', {
    //         detail: response,
    //         bubbles: true,
    //         cancelable: true,
    //       });
    //       window.dispatchEvent(event);
    //     },
    //     prefill: {
    //       name: '',
    //       email: '',
    //       contact: '',
    //     },
    //     notes: {
    //       address: '',
    //     },
    //     theme: {
    //       color: '#0ec6c6',
    //     },
    //   };

    //   var rzp1 = new Razorpay(options);
    //   rzp1.open();

    //   rzp1.on('payment.failed', (response: any) => {
    //     let statusDetail = {
    //       status: 'Payment Failed',
    //       payment_id: response.error.metadata.payment_id,
    //       razorpay_signature: '',
    //       description: response.error.description,
    //       id: this.HWMDetailsId,
    //     };
    //     this.paymentservice
    //       .hwmPayment(
    //         response.error.metadata.order_id,
    //         this.addArray[0].member_id,
    //         statusDetail
    //       )
    //       .subscribe((res: any) => { });
    //   });
    // } else if (value == 'trust') {
    //   let options = {
    //     key: environment.rezorpay_key,
    //     amount: this.trustArray[0].amount,
    //     name: 'UCCI',
    //     image: environment.rezorpay_logo,
    //     order_id: this.trustArray[0].order_id,
    //     handler: function (response: any) {
    //       var event = new CustomEvent('payment.success', {
    //         detail: response,
    //         bubbles: true,
    //         cancelable: true,
    //       });
    //       window.dispatchEvent(event);
    //     },
    //     prefill: {
    //       name: '',
    //       email: '',
    //       contact: '',
    //     },
    //     notes: {
    //       address: '',
    //     },
    //     theme: {
    //       color: '#0ec6c6',
    //     },
    //   };

    //   var rzp1 = new Razorpay(options);
    //   rzp1.open();

    //   rzp1.on('payment.failed', (response: any) => {
    //     let statusDetail = {
    //       status: 'Payment Failed',
    //       payment_id: response.error.metadata.payment_id,
    //       razorpay_signature: '',
    //       description: response.error.description,
    //       id: this.HWMDetailsId,
    //     };
    //     this.paymentservice
    //       .hwmPayment(
    //         response.error.metadata.order_id,
    //         this.trustArray[0].member_id,
    //         statusDetail
    //       )
    //       .subscribe((res: any) => { });
    //   });
    // }
  }
  @HostListener('window:payment.success', ['$event'])
  onPaymentSuccess(event: any): void {
    let statusDetail = {
      status: 'Payment Success',
      payment_id: event.detail.razorpay_payment_id,
      razorpay_signature: event.detail.razorpay_signature,
      description: 'Payment Success',
      type: 'HWM Form',
    };
    // this.paymentservice
    //   .hwmPayment(
    //     event.detail.razorpay_order_id,
    //     this.trustArray[0].member_id,
    //     statusDetail
    //   )
    //   .subscribe((res: any) => {
    //     if (res.code == 200) {
    //       this.messageService.add({
    //         severity: 'success',
    //         detail: res.message,
    //       });
    //     }
    //   });
  }
  onXLSExport() {
    let filteredBy
    this.excelData = [];
    this.filteredXLSData = [];
    if (this.dataTable.filteredValue != null) {
      this.filteredXLSData = this.dataTable.filteredValue;
      filteredBy= this.utility.getFilteredByName(this.dataTable)

    } else if (this.dataTable.filteredValue == null) {
      this.filteredXLSData = this.hwmMemberList;
    }
    for (let i = 0; i < this.filteredXLSData.length; i++) {
      let pre1 = [];

      if (
        this.filteredXLSData[i]?.nominee1?.preferred_communication?.includes(
          '"['
        )
      ) {
        pre1 = JSON.parse(
          this.filteredXLSData[i]?.nominee1?.preferred_communication
        );
      } else pre1 = this.filteredXLSData[i]?.nominee1?.preferred_communication;

      let pre2 = [];

      if (
        this.filteredXLSData[i]?.nominee2?.preferred_communication?.includes(
          '"['
        )
      ) {
        pre2 = JSON.parse(
          this.filteredXLSData[i]?.nominee1?.preferred_communication
        );
      } else pre2 = this.filteredXLSData[i]?.nominee2?.preferred_communication;

      let ia1 = [];
      for (
        let j = 0;
        j < this.filteredXLSData[i]?.nominee1_interest?.length;
        j++
      ) {
        if (this.filteredXLSData[i]?.nominee1_interest?.length > 0) {
          ia1.push(
            this.filteredXLSData[i]?.nominee1_interest[j]?.interest_area
          );
        } else ia1 = null;
      }
      let ia2 = [];
      for (
        let j = 0;
        j < this.filteredXLSData[i]?.nominee2_interest?.length;
        j++
      ) {
        if (this.filteredXLSData[i]?.nominee2_interest?.length > 0) {
          ia2.push(
            this.filteredXLSData[i]?.nominee2_interest[j]?.interest_area
          );
        } else ia2 = null;
      }
      let product: any;

      if (this.filteredXLSData[i]?.hwm_details[0]?.products_details) {
        product = JSON.parse(
          this.filteredXLSData[i]?.hwm_details[0]?.products_details
        );
      }

      let ecDetails = this.filteredXLSData[i]?.hwm_details[0]
        ?.ec_consents_status
        ? JSON.parse(
          this.filteredXLSData[i]?.hwm_details[0]?.ec_consents_status
        )
        : null;
      let totalAmount = Number((this.filteredXLSData[i]?.successful_payment[0]?.amount) ?? 0) + Number((this.filteredXLSData[i]?.successful_payment[1]?.amount) ?? 0);

      
let memberSince = this.filteredXLSData[i]?.member_since;
let formattedDate 
if (memberSince) {
    let parts = memberSince.split('-');
    let date = new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));

     formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    console.log(formattedDate , "formattedDate"); 
}
      this.excelData.push({
        'Sr No': i + 1,
        Status: this.filteredXLSData[i]?.status,
        'Company Name': this.filteredXLSData[i]?.company_name,
        'Member Since':formattedDate?formattedDate : ' ',
        'invoice_payment': totalAmount ?? '-',
        'GST Number': this.filteredXLSData[i]?.gst_number,
        CIN: this.filteredXLSData[i]?.cin_number,
        'Factory Address':
          this.filteredXLSData[i]?.hwm_details[0]?.factory_address?.address,
        Unit: this.filteredXLSData[i]?.hwm_details[0]?.unit,
        'Factory City':
          this.filteredXLSData[i]?.hwm_details[0]?.factory_address?.city,
        'Factory State':
          this.filteredXLSData[i]?.hwm_details[0]?.factory_address?.state,
        'Factory Pin Code':
          this.filteredXLSData[i]?.hwm_details[0]?.factory_address?.pin_code,
        'Registration Number': this.filteredXLSData[i]?.registration_number,
        'Capital Investment': this.filteredXLSData[i]?.capital_investment,
        Website: this.filteredXLSData[i]?.website,
        Email: this.filteredXLSData[i]?.email,
        'Nature of Business': this.filteredXLSData[i]?.nob?.name,
        'Occupier/Owner Name': this.filteredXLSData[i]?.nominee1?.name,
        'Occupier/Owner Designation':
          this.filteredXLSData[i]?.nominee1?.designation,
        'Occupier/Owner PAN': this.filteredXLSData[i]?.nominee1?.pan_number,
        'Occupier/Owner Email': this.filteredXLSData[i]?.nominee1?.email,
        'Occupier/Owner Office Phone':
          this.filteredXLSData[i]?.nominee1?.office_phone,
        'Occupier/Owner Mobile Number':
          this.filteredXLSData[i]?.nominee1?.mobile,
        'Occupier/Owner Address':
          this.filteredXLSData[i]?.nominee1_address?.address,
        'Occupier/Owner City': this.filteredXLSData[i]?.nominee1_address?.city,
        'Occupier/Owner State':
          this.filteredXLSData[i]?.nominee1_address?.state,
        'Occupier/Owner Pin Code':
          this.filteredXLSData[i]?.nominee1_address?.pin_code,
        'Occupier/Owner Department':
          this.filteredXLSData[i]?.nominee1?.department,
        'Occupier/Owner Preferred Communication': pre1,
        'Occupier/Owner Interest Area': ia1.toString(),

        'Contact Person Name': this.filteredXLSData[i]?.nominee2?.name,
        'Contact Person Designation':
          this.filteredXLSData[i]?.nominee2?.designation,
        'Contact Person PAN': this.filteredXLSData[i]?.nominee2?.pan_number,
        'Contact Person Email': this.filteredXLSData[i]?.nominee2?.email,
        'Contact Person Office Phone':
          this.filteredXLSData[i]?.nominee2?.office_phone,
        'Contact Person Mobile Number':
          this.filteredXLSData[i]?.nominee2?.mobile,
        'Contact Person Address':
          this.filteredXLSData[i]?.nominee2_address?.address,
        // 'Contact Person City': this.filteredXLSData[i]?.nominee2_address?.city,
        // 'Contact Person State':
        //   this.filteredXLSData[i]?.nominee2_address?.state,
        // 'Contact Person Pin Code':
        //   this.filteredXLSData[i]?.nominee2_address?.pin_code,
        'Contact Person Department':
          this.filteredXLSData[i]?.nominee2?.department,
        'Contact Person Preferred Communication': pre2,
        'Contact Person Interest Area': ia2.toString(),
        'Manufactured Products (Annually)':
          this.filteredXLSData[i]?.hwm_details[0]?.products_details,
        'Types of Hazardous Waste':
          this.filteredXLSData[i]?.hwm_details[0]?.hw_details,
        'Estimated or Actual Investment':
          this.filteredXLSData[i]?.hwm_details[0]?.investment,
        'Expected Date for Disposal of HW': moment(
          this.filteredXLSData[i]?.hwm_details[0]?.hw_disposal_expected_date
        ).format('DD/MM/YYYY'),
        'Production Commencement Date': moment(
          this.filteredXLSData[i]?.hwm_details[0]?.production_commencement_date
        ).format('DD/MM/YYYY'),
        'Incineration Facility Required':
          this.filteredXLSData[i]?.hwm_details[0]
            ?.incineration_facility_required == 1
            ? 'Yes'
            : 'No',
        'The Production Has Not Started Yet':
          this.filteredXLSData[i]?.hwm_details[0]?.is_production_started == 1
            ? 'Yes'
            : 'No',
        Turnover: this.filteredXLSData[i]?.turn_over?.component,
        'Environmental Clearance (EC) Applied': this.filteredXLSData[i]
          ?.hwm_details[0]?.ec_consents_status
          ? ecDetails[0]?.is_ec_applied
          : null,
        'Environmental Clearance (EC) Obtained': this.filteredXLSData[i]
          ?.hwm_details[0]?.ec_consents_status
          ? ecDetails[0]?.is_ec_obtained
          : null,
        'Environmental Clearance (EC) Hold Reason': this.filteredXLSData[i]
          ?.hwm_details[0]?.ec_consents_status
          ? ecDetails[0]?.ec_hold_reason
          : null,
        'Consent To Establish (CTE) Applied': this.filteredXLSData[i]
          ?.hwm_details[0]?.ec_consents_status
          ? ecDetails[1]?.is_cte_applied
          : null,
        'Consent To Establish (CTE) Obtained': this.filteredXLSData[i]
          ?.hwm_details[0]?.ec_consents_status
          ? ecDetails[1]?.is_cte_obtained
          : null,
        'Consent To Establish (CTE) Hold Reason': this.filteredXLSData[i]
          ?.hwm_details[0]?.ec_consents_status
          ? ecDetails[1]?.cte_hold_reason
          : null,
        'Consent To Operate (CTO) Applied': this.filteredXLSData[i]
          ?.hwm_details[0]?.ec_consents_status
          ? ecDetails[2]?.is_cto_applied
          : null,
        'Consent To Operate (CTO) Obtained': this.filteredXLSData[i]
          ?.hwm_details[0]?.ec_consents_status
          ? ecDetails[2]?.is_cto_obtained
          : null,
        'Consent To Operate (CTO) Hold Reason': this.filteredXLSData[i]
          ?.hwm_details[0]?.ec_consents_status
          ? ecDetails[2]?.cto_hold_reason
          : null,
        'HWM Authorization Obtained': this.filteredXLSData[i]?.hwm_details[0]
          ?.ec_consents_status
          ? ecDetails[3]?.is_hwm_auth_applied
          : null,
        'HWM Authorization Applied': this.filteredXLSData[i]?.hwm_details[0]
          ?.ec_consents_status
          ? ecDetails[3]?.is_hwm_auth_obtained
          : null,
        'HWM Authorization Hold Reason': this.filteredXLSData[i]?.hwm_details[0]
          ?.ec_consents_status
          ? ecDetails[3]?.hwm_hold_reason
          : null,
        Remarks: this.filteredXLSData[i]?.remark,
      });
    }

    this.apiService.exportExcel(this.excelData, 'Hwm-Member-List' , filteredBy);
  }

  onPDFExport() {
    this.pdfData = [];
    this.filteredPDFData = [];
    let filteredBy
    if (this.dataTable.filteredValue != null) {
      this.filteredPDFData = this.dataTable.filteredValue;
      filteredBy= this.utility.getFilteredByName(this.dataTable)
    } else if (this.dataTable.filteredValue == null) {
      this.filteredPDFData = this.hwmMemberList;
    }

    for (let i = 0; i < this.filteredPDFData.length; i++) {
      let totalAmount = Number((this.filteredPDFData[i]?.successful_payment[0]?.amount) ?? 0) + Number((this.filteredPDFData[i]?.successful_payment[1]?.amount) ?? 0)

      
let memberSince = this.filteredPDFData[i]?.member_since;
let formattedDate 
if (memberSince) {
    let parts = memberSince.split('-');
    let date = new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));

     formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    console.log(formattedDate , "formattedDate"); 
}
      this.pdfData.push({
        sr_no: i + 1,
        company_name: this.filteredPDFData[i]?.company_name,
        invoice_payment: totalAmount ?? '-',
        email: this.filteredPDFData[i]?.email,
        office_telephone: this.filteredPDFData[i]?.nominee1?.office_phone,
        gst_number: this.filteredPDFData[i]?.gst_number,
        nominee1Name: this.filteredPDFData[i]?.nominee1?.name,
        nominee2Name: this.filteredPDFData[i]?.nominee2?.name,
        member_since:formattedDate?formattedDate : ' ',
      });
    }
    this.apiService.exportPdf(
      this.pdfData,
      this.exportColumns,
      'Hwm-Member-List',
      filteredBy
    );
  }
  // *******************************************header filter dropdown process function *****************************************
  search(event: any, index: number, fields?: any) {
    // Clear existing filter values
    Object.keys(this.dataTable.filters).forEach((key) => {
        this.dataTable.filters[key]['value'] = null;
    });

    // Store filter values
    this.multipleFields.forEach((res: any) => {
        if (!this.keyNames?.includes(res?.key?.name)) {
            this.filterValues.push({ value: res?.value, columnName: res?.key?.name == 'Area of Interest' ? 'nominee_Interest' : res?.key?.name, matchMode: 'contains' });
        } else if (res?.key?.name == 'turn_over') {
            this.filterValues.push({ value: res?.value, columnName: 'turn_over.component', matchMode: 'contains' });
        } else if (res?.key?.name == 'Nature of Business') {
            this.filterValues.push({ value: res?.value, columnName: 'nob.name', matchMode: 'contains' });
        }
    });

    // Apply global filter value if available
    if (this.filterVal) {
        this.filterValues.push({ value: this.filterVal, columnName: 'contains', matchMode: 'contains' });
    }
}

  // turnover and area of interest of business dropdown
  getDropdownEvent(event: any, index: any) {
    if (event == 'turn_over') {
      this.multipleFields[index].dropdownKey = this.turnOverData;
      this.multipleFields[index].value = '';
      let name = this.multipleFields[index]?.key?.name;
      if (this.dataTable.filters[name]) {
        this.dataTable.filters[name]['value'] = '';
      }
    } else if (event == 'Area of Interest') {
      this.multipleFields[index].dropdownKey = this.areaOfInterest;
      this.multipleFields[index].value = '';
      let name = this.multipleFields[index]?.key?.name;
      if (this.dataTable.filters[name]) {
        this.dataTable.filters[name]['value'] = '';
      }
    } else if (event == 'Nature of Business') {
      this.multipleFields[index].dropdownKey = this.natureofBusinessListData;
      this.multipleFields[index].value = '';
      let name = this.multipleFields[index]?.key?.name;
      if (this.dataTable.filters[name]) {
        this.dataTable.filters[name]['value'] = '';
      }
    } else if (event == 'status') {
      this.multipleFields[index].dropdownKey = [
        { label: 'Rejected', value: 'Rejected' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Approved', value: 'Approved' },
        { label: 'In Progress', value: 'In Progress' },
      ];
      this.multipleFields[index].value = '';
      let name = this.multipleFields[index]?.key?.name;
      if (this.dataTable.filters[name]) {
        this.dataTable.filters[name]['value'] = '';
      }
    } else {
      this.multipleFields[index].dropdownKey = [];
      this.multipleFields[index].value = '';
      let name = this.multipleFields[index]?.key?.name;
      if (this.dataTable.filters[name]) {
        this.dataTable.filters[name]['value'] = '';
      }
    }
  }
  addMoreFilters() {
    this.multipleFields.push({ key: null, value: null, dropdownKey: [] });
  }
  removevalue(i) {
    let name = this.multipleFields[i]?.key?.name;
    if (
      this.multipleFields[i]?.value != null &&
      this.multipleFields[i]?.value != ''
    ) {
      this.dataTable.filters[name]['value'] = null;
    }
    this.multipleFields.splice(i, 1);
    this.search(this.multipleFields[i - 1].value, i - 1, this.multipleFields);
  }

  deleteConfirmation(regularMemberList: any) {
    // if(regularMemberList.is_delete){
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      accept: (res: any) => {
        this.apiService.deleteRegularMemberByComittee(regularMemberList.id).subscribe((res: any) => {
          if (res.code == 200) {
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });

            this.getHwmMemberList();
          }
          else {
            this.messageService.add({
              severity: 'error',
              detail: res.message,
            });
          }
        })
      },
    });
    // }
  }
  paymentDetails(addmission_id?: any, trusPay_id?: any, details?: any) {
    this.addArray = [];
    this.trustArray = [];
    this.rowId = details.id;
    this.HwmDetailsId = details?.hwm_details[0]?.id
    this.hwmDetails.map((res: any) => {
      res.map((data: any) => {
        if (data.id == addmission_id) {
          this.isShow = true;
          this.addArray.push(data);
        }
        if (data.id == trusPay_id) {
          this.isShow = true;
          this.trustArray.push(data);
        }
        // this.addArray = res?.filter((x) => x.id == addmission_id);
        // this.trustArray = res?.filter((x) => x.id == trusPay_id);
      })

    })

    // this.HWMDetailsId = data?.id;
  }

  //checkpermissions
  async checkPermissions() {
    let userPermissions: any;
    userPermissions = await this.utility.getUserRoles();
    this.userPermission = userPermissions.filter(res => res.permission.title == 'HWM Member');
    this.userPermission.map((res) => {
      // if (res.permission.name == 'Create') { this.createPermission = true }
      if (res.permission.name == 'Edit') { this.updatePermission = true }
      else if (res.permission.name == 'Delete') { this.deletePermission = true }
      else if (res.permission.name == 'View') { this.viewPermission = true }
      else if (res.permission.name == 'Approval') { this.approvalPermission = true }
      else if (res.permission.name == 'Create') { this.enableAddButton = true }

    })
  }


  routerToHWMMember(hwmListId: any) {
    if (this.localStorage.role == 'HWM Owner' || this.localStorage.role == 'HWM Contact Person' || this.localStorage.role == 'Guest') {
      this.router.navigateByUrl('/user-panel/edit-profile/hwm-member');
    }
    else if (this.localStorage.role == 'Admin' || this.localStorage.role == 'UCCI Staff') {
      this.router.navigateByUrl('/user-panel/edit-membership/hwm-member/' + hwmListId);
    }
  }
  paymentForm = this.fb.group({
    paid_by: new FormControl('', Validators.required),
    payment_date: new FormControl('', Validators.required),
    cheque_date: new FormControl(''),
    cheque_no: new FormControl(''),
    bank_name: new FormControl(''),
    trust_paid_by: new FormControl('', Validators.required),
    trust_payment_date: new FormControl('', Validators.required),
    trust_cheque_date: new FormControl(''),
    trust_cheque_no: new FormControl(''),
    trust_bank_name: new FormControl('')
  });
  submit() {
    if (this.paymentForm.valid) {
      let formData = new FormData();

      let trust_payment_date_on = moment(
        this.paymentForm.value.trust_payment_date
      ).format('YYYY/MM/DD');

      let trust_cheque_date_on = moment(
        this.paymentForm.value.trust_cheque_date
      ).format('YYYY/MM/DD');

      let payment_date_on = moment(
        this.paymentForm.value.payment_date
      ).format('YYYY/MM/DD');

      let cheque_date_on = moment(
        this.paymentForm.value.cheque_date ? this.paymentForm.value.cheque_date : ''
      ).format('YYYY/MM/DD');

      let data = this.paymentForm.value;
      if (cheque_date_on == 'Invalid date' || trust_cheque_date_on == 'Invalid date') {
        formData.append('payment_date', payment_date_on);
        formData.append('paid_by', data?.paid_by);
        formData.append('cheque_no', data?.cheque_no);
        formData.append('bank_name', data?.bank_name);

        formData.append('trust_paid_by', data?.trust_paid_by);
        formData.append('trust_cheque_date', payment_date_on);
        formData.append('trust_cheque_no', data?.trust_cheque_no);
        formData.append('trust_bank_name', data?.trust_bank_name);
      } else {
        formData.append('trust_payment_date', trust_cheque_date_on);
        formData.append('trust_paid_by', data?.trust_paid_by);
        formData.append('trust_cheque_date', payment_date_on);
        formData.append('trust_cheque_no', data?.trust_cheque_no);
        formData.append('trust_bank_name', data?.trust_bank_name);

        formData.append('cheque_date', cheque_date_on);
        formData.append('paid_by', data?.paid_by);
        formData.append('payment_date', payment_date_on);
        formData.append('cheque_no', data?.cheque_no);
        formData.append('bank_name', data?.bank_name);
      }
      this.apiService.HwmMarkAsPaid(this.HWMDetailsId, formData).subscribe((res: any) => {
        this.getHwmMemberList();
        this.messageService.add({
          severity: 'success',
          detail: res?.message,
        });
        this.paymentDialog = false;
      })
    }
  }
  showPaymentDialog(member_id?: any) {

    this.paymentDialog = true;
    this.HWMDetailsId = member_id?.hwm_details[0]?.id;
  }
  //for termination of member
  terminatted(member_id?:any ,value?: any) {
    if(value == "terminated"){
      const payload:any = {
        member_id:member_id,
        status:"terminated"
      }
      this.apiService.terminatedHwm(payload).subscribe((res: any) => {
        this.getHwmMemberList();
        this.messageService.add({
          severity: 'success',
          detail: res?.message,
        });
      })
      console.log("value",value, "Member Id",member_id);
    }
    else if(value == "provisionally terminated"){
      const payload:any = {
        member_id:member_id,
        status:"provisionally terminated"
      }
      this.apiService.terminatedHwm(payload).subscribe((res: any) => {
        this.getHwmMemberList();
        this.messageService.add({
          severity: 'success',
          detail: res?.message,
        });
      })
    }
    else{
      this.messageService.add({
        severity: 'Something went wrong'
      });
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.paymentForm.controls;
  }
  // get renewal listing
  getRenewalList() {
    if (localStorage.getItem('member_id')) {
      let memberId: any = JSON.parse(localStorage.getItem('member_id'))
      this.apiService.memberRenewalList(memberId).then((res: any) => {
        this.renewalListing = res?.data;
      })
    }
  }
  showTable(tableType: string) {
    if (tableType == 'hwm_listing') {
      this.showHwmListing = true;
      this.showRenewalListing = false;
    } else if (tableType == 'renewal_listing') {
      this.showHwmListing = false;
      this.showRenewalListing = true;
    }
  }
  getUserName(id){
   this.apiService.getUserbyId(id).subscribe((res: any) =>{
      console.log(res);
      return res?.name
    });
  }
// Function to apply stored filter values
applyFilter() {
  this.filterValues.forEach((filter) => {
      this.dataTable.filter(filter.value, filter.columnName, filter.matchMode);
  });

  this.filterValues = [];
}


}
