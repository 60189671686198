<p-toast></p-toast>
<p-confirmDialog [breakpoints]="{'850px' : '90vw'}" [style]="{width: '50vw'}" header="Confirmation"></p-confirmDialog>
<!-- ********************** Main Content ******************** -->
<ng-container *ngIf="hideDataForAnotherComponent">
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
        <h1>{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{ Content.title }}</li>
        </ol>
    </div>
</ng-container>

<section class="regular-member-list">
    <!-- Radio button selection to show table  -->
    <div class="pt-4" *ngIf="localStorage.role == 'Nominee 1' || localStorage.role == 'Nominee 2'">
        <div class="row">
            <div class="col-lg-6 col-12">
                <div class="form-group">
                    <div class="form-check">
                        <input type="radio" id="radio1" class="form-check-input" [checked]="showListing"
                            (click)="showTable('listing')" name="radioModel" />
                        <label class="form-check-label">
                            Membership Details
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12">
                <div class="form-group">
                    <div class="form-check">
                        <input type="radio" id="radio2" class="form-check-input" [checked]="!showListing"
                            (click)="showTable('renewal_listing')" name="radioModel" />
                        <label class="form-check-label">
                            Membership Renewal Details
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showListing">
        <div class="row align-items-end pt-4 mb-3">
            <div class="col-md">
                <ng-container *ngFor="let data of multipleFields; let i = index">
                    <div class="row my-2" *ngIf="hideDataForAnotherComponent">
                        <div class="col-md-4 mb-2">
                            <p-dropdown [options]="objectKeysArray" (onChange)="getDropdownEvent(data?.key?.name, i)"
                                optionLabel="name" [(ngModel)]="data.key" [filter]="true" filterBy="name"
                                [showClear]="clearShow" placeholder="Select Option" [resetFilterOnHide]="true">
                                <ng-template pTemplate="selectedItem">
                                    <div class="country-item country-item-value" *ngIf="multipleFields[i]">
                                        <span
                                            *ngIf="multipleFields[i]?.key?.name != 'turn_over'">{{multipleFields[i]?.key?.name.replace('_',
                                            ' ') | titlecase}}</span>
                                        <span
                                            *ngIf="multipleFields[i]?.key?.name == 'turn_over'">{{multipleFields[i]?.key?.name.replace('_',
                                            '') | titlecase}}</span>
                                    </div>
                                </ng-template>
                                <ng-template let-value pTemplate="item">
                                    <div class="country-item">
                                        <div *ngIf="value.name != 'turn_over'">{{value.name.replace('_', ' ') | titlecase }}
                                        </div>
                                        <div *ngIf="value.name == 'turn_over'">{{value.name.replace('_', '') | titlecase }}
                                        </div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="col col-md-4 mb-2" >
                            <input type="text" *ngIf="!(data.dropdownKey?.length > 0)" [(ngModel)]="data.value"
                                (input)="search($event.target.value, i , '')" class="form-control" placeholder="Search Keyword">
                            <p-dropdown *ngIf="data.dropdownKey?.length > 0" [options]="data.dropdownKey" optionValue="value"
                                (onChange)="search($event.value, i , '', $event)" filterBy="label" [filter]="true"
                                [(ngModel)]="data.value" placeholder="Select Option" optionLabel="label">
                            </p-dropdown>
                        </div>
                        <div class="col-auto mb-2" >
                            <p-button *ngIf="data.value?.length > 0 && data.key"
                                (click)="addMoreFilters()" class="button" icon="bx bx-plus" iconPos="right" class="mr-2"
                                type="button">
                            </p-button>

                            <p-button *ngIf="i!= 0" type="button" class="ml-2" icon="bx bx-minus" iconPos="right"
                                (click)="removevalue(i)">
                            </p-button>


                        </div>
                    </div>
                    
                </ng-container>
            </div>
            <div class="col-md-auto">
                <p-button type="button" class="mx-2" (click)="applyFilter()" tooltipPosition="bottom" label="Apply Filter"></p-button>
            </div>
        </div>

        <div class="theader" *ngIf="hideDataForAnotherComponent">
            <div class="d-flex">
                <span class="p-input-icon-left ml-auto mr-2">
                    <i class="pi pi-search"></i>
                    <input class="form-control" pInputText type="text" (input)="dataTable.filterGlobal($event.target.value,
                            'contains')" placeholder="Search Keyword" [(ngModel)]="filterVal" />
                </span>
                <p-button type="button" class="mx-2" (click)="reset(nameFilter)" icon="bx bx-reset"
                    [pTooltip]="tooltipText" tooltipPosition="bottom">
                </p-button>
                <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="onXLSExport()"
                    class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"
                    *ngIf="localStorage.role == 'Admin'"></button>
                <button type="button" pButton pRipple icon="pi pi-file-pdf" (click)="onPDFxport()"
                    class="p-button-warning mr-2" pTooltip="PDF" tooltipPosition="bottom"
                    *ngIf="localStorage.role == 'Admin'"></button>


            </div>
            <ng-container>
                <p-button *ngIf="enableAddButton" type="button" class="ml-2"
                    routerLink="/user-panel/regular-member-form" label="Add"></p-button>
            </ng-container>
        </div>
        <!-- Regular Member Listing -->
        <p-table
            *ngIf="((localStorage.role == 'Admin' || localStorage.role == 'UCCI Staff') ? response?.data?.length > 0 : response?.member_data?.length == 0)"
            #nameFilter dataKey="id" [rows]="10" [showCurrentPageReport]="true" styleClass="p-datatable-sm
                p-datatable-customers" responsiveLayout="scroll" scrollable="true" scrollHeight="600px" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true"
            currentPageReportTemplate="{totalRecords} of {{datalength}}
                records" [globalFilterFields]="['company_name',
                'email', 'website', 'membership_no']"
            [value]="regularMemberListData == false ? regularMemberList : verifiedMembers"
            [tableStyle]="{'min-width': '50rem', 'width' : '100%'}">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 62px">S. No.</th>
                    <th style="min-width: 100px;">
                        Action
                    </th>
                    <th style="min-width: 100px;"
                        *ngIf="localStorage?.role == roleObject?.nominee1 || localStorage?.role == roleObject?.nominee2 || localStorage?.role == roleObject?.guest">
                        Payment 
                    </th>
                    <th style="min-width: 200px;" pSortableColumn="status">
                        Status<p-sortIcon field="status"> </p-sortIcon>
                    </th>


                    <!-- <th style="min-width: 200px;text-align: center;">
                        Invoice Amount
                    </th> -->
                    <th style="min-width: 148px" pSortableColumn="member_since">Member Since<p-sortIcon field="member_since"> </p-sortIcon></th>
                    <th style="min-width: 200px" pSortableColumn="membership_no">Membership Number<p-sortIcon field="membership_no"> </p-sortIcon></th>
                    <th style="min-width: 200px" pSortableColumn="company_name">
                        Company Name<p-sortIcon field="company_name"> </p-sortIcon>
                    </th>
                    <th style="min-width: 250px" pSortableColumn="email">Email<p-sortIcon field="email"> </p-sortIcon></th>
                    <th style="min-width: 200px" pSortableColumn="email">Website<p-sortIcon field="email"> </p-sortIcon></th>
                    <th style="min-width: 200px" pSortableColumn="office_telephone">
                        Office Telephone<p-sortIcon field="office_telephone"> </p-sortIcon>
                    </th>
                </tr>

                <tr *ngIf="hideDataForAnotherComponent">
                    <th *ngFor="let item of colFilter" [ngSwitch]="item.field">
                        <p-columnFilter [showClearButton]="false" *ngSwitchCase="'status'" field="status"
                            matchMode="equals" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [appendTo]="nameFilter" [options]="status"
                                    (onChange)="filter($event.value)" placeholder="Select" [showClear]="false"
                                    [resetFilterOnHide]="true">
                                    <ng-template let-option pTemplate="item">
                                        <span>{{option.label}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                        
                        <ng-container
                            *ngIf="localStorage?.role == roleObject?.nominee1 || localStorage?.role == roleObject?.nominee2 || localStorage?.role == roleObject?.guest">
                            <span *ngSwitchCase="'payment'"></span>
                        </ng-container>

                       
                        <input placeholder="Keyword" *ngSwitchCase="'company_name'" pInputText
                            [(ngModel)]="company_name" class="p-inputtext p-component p-element" type="text" (input)="nameFilter.filter($event.target.value,
                                'company_name', 'contains')" style="height:
                                30px;">
                        <input placeholder="Keyword" *ngSwitchCase="'membership_no'" pInputText
                            [(ngModel)]="membership_no" class="p-inputtext p-component p-element" type="text" (input)="nameFilter.filter($event.target.value,
                                'membership_no', 'contains')" style="height:
                                30px;">
                        <input placeholder="Keyword" *ngSwitchCase="'email'" pInputText [(ngModel)]="email"
                            class="p-inputtext p-component p-element" type="text" (input)="nameFilter.filter($event.target.value,
                                'email', 'contains')" style="height: 30px;">
                        <input placeholder="Keyword" *ngSwitchCase="'website'" pInputText [(ngModel)]="website"
                            class="p-inputtext p-component p-element" type="text" (input)="nameFilter.filter($event.target.value,
                                'website', 'contains')" style="height: 30px;">
                        <input placeholder="Keyword" *ngSwitchCase="'office_telephone'" pInputText
                            [(ngModel)]="office_telephone" class="p-inputtext p-component p-element" type="text"
                            (input)="nameFilter.filter($event.target.value,
                                'office_telephone', 'contains')" style="height:
                                30px;">
                    </th>
                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-regularMemberList let-i="rowIndex">
                <tr>
                    <td>
                        {{ i + 1 }}
                    </td>
                    <td style="min-width: 100px;">
                        <div class="view-buttons">
                            <button *ngIf="enableViewButton" pButton pRipple class="p-button-rounded
                                    p-button-text
                                    p-button-info" (click)="showMaximizableDialog(regularMemberList)" pTooltip="View"
                                tooltipPosition="bottom">
                                <i class="far fa-eye"></i>
                            </button>
                            <button *ngIf="enableEditButton" pButton pRipple class="p-button-rounded
                                    p-button-text p-button-info" pTooltip="Edit" tooltipPosition="bottom"
                                icon="bx bx-pencil" (click)="routerToRegularember(regularMemberList.id)"></button>

                             <button *ngIf="this.localStorage.username == 'Chief Executive Officer' || this.localStorage.username == 'Admin'" pButton pRipple pTooltip="Mark as Paid" (click)="showPaymentDialog(regularMemberList)" tooltipPosition="bottom" [disabled]="regularMemberList?.status != 'Pending'" class="p-button-rounded 
                                 p-button-text
                               p-button-success mr-2" ><i class='bx
                                bxs-check-circle'></i></button>    
                                <button pButton pRipple class="p-button-rounded p-button-text p-button-danger"
                                [disabled]="regularMemberList?.status != 'Approved' && regularMemberList?.status !== 'Provisionally Terminated'"
                                (click)="terminatted(regularMemberList.id,'terminated')" pTooltip="Terminate" *ngIf="localStorage.username == 'Chief Executive Officer' || localStorage.username == 'Admin' || localStorage.username == 'President'"
                                 tooltipPosition="bottom">
                                <i class="bx bxs-message-alt-x"></i>
                                </button>
                                <button pButton pRipple class="p-button-rounded p-button-text p-button-danger"
                                [disabled]="regularMemberList?.status != 'Approved'"
                                (click)="terminatted(regularMemberList.id,'provisionally terminated')" pTooltip="Provisionally Terminate" *ngIf="localStorage.username == 'Chief Executive Officer' || localStorage.username == 'Admin' || localStorage.username == 'President'"
                                 tooltipPosition="bottom">
                                <i class="bx bx-message-alt-x"></i>
                                </button>
                            <!--********************** Approve and rejection process for admin role ************************-->
                            <ng-container >
                                <ng-container *ngIf="regularMemberList.status == statusObject.verified ">
                                    <!-- enable button when proposed_by_status and seconded_by_status is approved -->
                                    <ng-container
                                        *ngIf="regularMemberList?.representatives[0]?.proposed_by_status == 'Approved' && regularMemberList?.representatives[0]?.seconded_by_status == 'Approved'">
                                        <button *ngIf="enableApprovalButton" pButton pRipple (click)="
                                        showConfirmationDialog(regularMemberList, 'approvedialog' )" class="p-button-rounded
                                                p-button-text
                                                p-button-success" style="padding:
                                                0.2rem!important;" pTooltip="Approve" tooltipPosition="bottom"><i
                                                class="far
                                                    fa-check-circle"></i>
                                        </button>
                                        <button *ngIf="enableApprovalButton" pButton pRipple (click)="showConfirmationDialog(regularMemberList,
                                                'rejectdialog')" class="p-button-rounded
                                                p-button-text p-button-danger" style="padding:
                                                0.2rem!important;" pTooltip="Reject" tooltipPosition="bottom">
                                            <i class="bx bx-message-alt-x"></i>
                                        </button>
                                    </ng-container>
                                    <!-- disable button when proposed_by_status and seconded_by_status is not approved -->
                                    <ng-container
                                        *ngIf="regularMemberList?.representatives[0]?.proposed_by_status != 'Approved' || regularMemberList?.representatives[0]?.seconded_by_status != 'Approved'">
                                        <button *ngIf="enableApprovalButton" pButton pRipple
                                            (click)="this.AlertDialog = true" class="p-button-rounded
                                                p-button-text
                                                p-button-success" style="padding:
                                                0.2rem!important;" pTooltip="Approve" tooltipPosition="bottom"><i
                                                class="far
                                                    fa-check-circle"></i>
                                        </button>
                                        <button *ngIf="enableApprovalButton"
                                         pButton pRipple (click)="this.AlertDialog = true" class="p-button-rounded
                                                p-button-text p-button-danger" style="padding:
                                                0.2rem!important;" pTooltip="Reject" tooltipPosition="bottom">
                                            <i class="bx bx-message-alt-x"></i>
                                        </button>
                                    </ng-container>
                                </ng-container>

                                <!-- disable button when staff not verified membership -->

                                <ng-container *ngIf="regularMemberList.status !=
                                    statusObject.verified">
                                    <button *ngIf="enableApprovalButton" pButton pRipple disabled (click)="
                                        showConfirmationDialog(regularMemberList, 'approvedialog' )" class="p-button-rounded
                                                p-button-text
                                                p-button-success" style="padding:
                                                0.2rem!important;" pTooltip="Approve" tooltipPosition="bottom"><i
                                            class="far
                                                    fa-check-circle"></i>
                                    </button>
                                    <button *ngIf="enableApprovalButton" pButton pRipple disabled (click)="showConfirmationDialog(regularMemberList,
                                                'rejectdialog')" class="p-button-rounded
                                                p-button-text p-button-danger" style="padding:
                                                0.2rem!important;" pTooltip="Reject" tooltipPosition="bottom">
                                        <i class="bx bx-message-alt-x"></i>
                                    </button>
                                </ng-container>
                            </ng-container>

                            <!--********************** Verification and rejection process for staff role ********************-->
                            <ng-container *ngIf="localStorage.role ==
                                    roleObject.staff">
                                <button *ngIf="enableVerificationButton" pButton pRipple (click)="showVerificationDialog(regularMemberList,
                                        'approve')" class="p-button-rounded p-button-text
                                        p-button-success" [disabled]="regularMemberList.status ==
                                        'Verified' || regularMemberList.status ==
                                        statusObject.pending &&
                                        regularMemberList.mode_of_payment !=
                                        'offline' || regularMemberList.status ==
                                        'Approved' || regularMemberList.status ==
                                        'Verification Rejected' ||
                                        regularMemberList.status == 'Rejected'" pTooltip="Verify"
                                    tooltipPosition="bottom">
                                    <i class="fas fa-badge-check"></i>
                                </button>
                                <button pButton pRipple (click)="showVerificationDialog(regularMemberList,
                                        'reject')" class="p-button-rounded p-button-text
                                        p-button-danger" pTooltip="Reject"
                                    [disabled]="(regularMemberList.status == statusObject.pending && regularMemberList.mode_of_payment == 'online') ||  regularMemberList.status == statusObject.approved || regularMemberList.status == statusObject.verified || regularMemberList.status == statusObject.rejected || regularMemberList.status == statusObject.verificationrejected"
                                    tooltipPosition="bottom">
                                    <i class="bx bx-message-alt-x"></i>
                                </button>
                            </ng-container>
                            <!-- <ng-container *ngIf="localStorage.role == 'Admin'">
                                <button *ngIf="enableDeleteButton" pButton pTooltip="delete"
                                    (click)="deleteConfirmation(regularMemberList)" class="p-button-rounded
                                p-button-text 
                                p-button-danger">
                                    <i class="pi pi-trash"></i>
                                </button>
                            </ng-container> -->
                        </div>
                    </td>
                    <td class="status" style="min-width: 200px;"
                        *ngIf="localStorage?.role == roleObject?.nominee1 || localStorage?.role == roleObject?.nominee2 || localStorage?.role == roleObject?.guest">
                        <ng-container
                            *ngIf="regularMemberList?.status =='Pending' && regularMemberList?.mode_of_payment == 'online'">
                            <span class="pay_now_btn" (click)="payment(regularMemberList)">Pay Now</span>
                        </ng-container>
                        <ng-container
                            *ngIf="regularMemberList?.status =='Pending' && regularMemberList?.mode_of_payment == 'offline'">
                            <span class="on-hold ">Not Paid</span>
                        </ng-container>
                        <ng-container *ngIf="regularMemberList?.status !='Pending'">
                            <span class="paid_btn">Paid</span>
                        </ng-container>
                    </td>
                    <td class="status">
                        <ng-container *ngIf=" regularMemberList?.status
                                    =='On Hold'">
                            <span class="on-hold">
                                {{ regularMemberList?.status }}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="regularMemberList?.status == 'In Progress'">
                            <span class="in-progress">
                                {{ regularMemberList?.status }}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="regularMemberList?.status ==
                                    statusObject.approved">
                            <span class="approved">
                                {{ regularMemberList?.status }}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="
                                    regularMemberList?.status ==
                                    statusObject.verified">
                            <span class="verify">
                                {{ regularMemberList?.status }}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="regularMemberList?.status ==
                                    'Pending'">
                            <span class="pending">
                                {{ regularMemberList?.status }}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="regularMemberList?.status ==
                                    'Rejected'">
                            <span class="rejected">
                                {{ regularMemberList?.status }}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="regularMemberList?.status ==
                                    'Verification Rejected'">
                            <span class="veri-rejected">
                                {{ regularMemberList?.status }}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="regularMemberList?.status
                        ==
                        'Terminated'">
                            <span class="terminated">
                                {{ regularMemberList?.status }}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="regularMemberList?.status
                        ==
                        'Provisionally Terminated'">
                            <span class="terminated" style="background-color: #692f35 !important">
                                {{ regularMemberList?.status }}
                            </span>
                        </ng-container>
                    </td>
                    
                    <td>{{ regularMemberList?.member_since ? (regularMemberList?.member_since | date:'dd-MM-yyyy') : '-' }}</td>

                    <td>{{regularMemberList?.membership_no ??
                        '-'}}</td>
                    <!-- <td style="text-align: center;">
                            {{(regularMemberList?.paid_amount?.amount | currency : 'INR') ?? '-' }}
                        </td> -->
                    <td>{{ regularMemberList?.company_name }}</td>
                    <td>{{ regularMemberList?.email }}</td>
                    <td>{{ regularMemberList?.website ?? '-'}}</td>
                    <td>
                        {{ regularMemberList?.office_telephone ?? '-'}}
                    </td>
                    <td class="center-text hide">
                        {{ regularMemberList?.applicant_constitution }}
                    </td>
                    <td class="center-text hide">
                        {{ regularMemberList?.registered_by }}
                    </td>
                    <td class="center-text hide">
                        {{ regularMemberList?.registration_number }}
                    </td>
                    <td class="center-text hide">
                        {{ regularMemberList?.capital_investment }}
                    </td>
                    <td class="center-text hide">
                        {{ regularMemberList?.applicant_registered }}
                    </td>
                    <td class="center-text hide">
                        {{ regularMemberList?.gst_number }}
                    </td>
                    <td class="center-text hide">
                        {{ regularMemberList?.member_category?.name }}
                    </td>
                    <td class="center-text hide">
                        {{ regularMemberList?.member_classification?.name }}
                    </td>
                    <td class="center-text hide">
                        {{ regularMemberList?.turn_over?.component }}
                    </td>
                    <td class="center-text hide">
                        {{ regularMemberList?.nob?.name }}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="9">No Data Found.</td>
                </tr>
            </ng-template>
        </p-table>
        <!-- Drafted data list -->
        <p-table *ngIf="(response?.member_data?.length > 0)" #nameFilter dataKey="id" [rows]="10"
            [showCurrentPageReport]="true" styleClass="p-datatable-sm
                p-datatable-customers" responsiveLayout="scroll" scrollable="true" scrollHeight="600px" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true"
            currentPageReportTemplate="{totalRecords} of {{datalength}}
                records" [globalFilterFields]="['company_name']" [value]="draftData"
            [tableStyle]="{'min-width': '50rem', 'width' : '100%'}">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width: 62px;">S. No.</th>
                    <th style="min-width: 160px;">
                        Status
                    </th>
                    <th style="min-width: 200px;">Company Name
                    </th>
                    <th style="min-width: 200px;">Username
                    </th>
                    <th style="min-width: 200px;">Useremail
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-i="rowIndex">
                <tr>
                    <td>
                        {{ i + 1 }}
                    </td>
                    <td class="status">
                        <span class="pending">
                            {{response?.member_data[0]?.changed_in}}
                        </span>
                    </td>
                    <td>{{data?.company_name}}</td>
                    <td>{{storageData?.username}}</td>
                    <td>{{storageData?.useremail}}</td>
                </tr>
            </ng-template>

        </p-table>
    </div>

    <!-- Regular Expiring Membership Listing -->
    <p-table #nameFilter dataKey="id" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true"
        *ngIf="(showRenewalListing && (localStorage.role == 'Nominee 1' || localStorage.role == 'Nominee 2'))"
        [rows]="10" [showCurrentPageReport]="true" styleClass="p-datatable-sm
        p-datatable-customers" responsiveLayout="scroll" scrollable="true" scrollHeight="600px" [value]="renewalListing"
        [tableStyle]="{'min-width': '50rem', 'width' : '100%'}">
        <ng-template pTemplate="header">
            <tr>
                <th style="min-width: 62px;">S. No.</th>
                <th style="min-width: 160px;">
                    Status
                </th>
                <th style="min-width: 160px;">
                    Membership Year
                </th>
                <th>Payment</th>
                <th>Expiring Date</th>
                <th style="min-width: 160px;">
                    Invoice
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data let-i="rowIndex">
            <tr>
                <td style="min-width: 80px;">{{i + 1}}</td>
                <td class="status">
                    <ng-container *ngIf="data?.verification_status =='Pending'">
                        <span class="pending">
                            {{ data?.verification_status }}
                        </span>
                    </ng-container>
                    <ng-container *ngIf="data?.verification_status =='Approved'">
                        <span class="approved">
                            {{ data?.verification_status }}
                        </span>
                    </ng-container>
                </td>
                <td>{{data?.membership_year}}</td>
                <td>{{(data?.payment?. amount | currency : 'INR')
                    ?? '-' }}</td>
                <td>{{data?.expire_at | date:'dd-MM-yyyy' }}</td>
                <td>
                    <!-- <a *ngIf="data?.invoice_pdf !=null" style="font-size: 0.8rem;
                            color: var(--mainColor);
                            font-weight: 700;" class="btn
                            btn-default" (click)="getImage(data?.invoice_pdf,'invoice')">
                        Invoice
                    </a> -->
                    <a *ngIf="data?.invoice_pdf != null" [href]="getImage(data?.invoice_pdf,'invoice')" style=" font-size: 0.8rem;
                                                        color: var(--mainColor);
                                                        font-weight: 700; text-decoration: underline;" target="_blank"
                        [download]="getImage(data?.invoice_pdf,'invoice')">Invoice</a>
                    <span *ngIf="data?.invoice_pdf ==null">-</span>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="9">No Data Found.</td>
            </tr>
        </ng-template>
    </p-table>
    <!-- Verification Dialog -->
    <p-dialog p-dialog header="Confirmation" [modal]="true" [style]="{width:
                '42vw'}" [breakpoints]="{'850px' : '90vw'}" [draggable]="false" [resizable]="false"
        [(visible)]="rejectionModal" (onHide)="onDialogClose()">
        <form [formGroup]="verificationrejectionForm" (ngSubmit)="onRejectPress(form)" #form="ngForm">
            <div class="row">
                <div class="col-12 mt-2">
                    <label class="mb-3" for="">
                        Reason<span class="required">*</span></label>
                    <textarea type="text" placeholder="Reason" formControlName="remark" class="form-control"
                        [class.is-invalid]="(form.submitted
                                    &&
                                    verificationrejectionForm.get('remark')?.invalid)
                                    ||
                                    verificationrejectionForm.get('remark')?.invalid
                                    &&
                                    verificationrejectionForm.get('remark').touched">
                                </textarea>
                    <div *ngIf="form.submitted &&
                                    h.remark.errors?.required ||
                                    h.remark.touched" class="alert
                                    alert-danger">
                        <div *ngIf="h.remark.errors?.required">
                            Reason is Required
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-listings-btn mt-3">
                <p-button type="submit" label="Save" pRipple></p-button>
            </div>

        </form>
    </p-dialog>

    <!-- Approve Reject Dialog for admin Role-->
    <p-dialog p-dialog header="Confirmation" [modal]="true" [style]="{width:
                '42vw'}" [breakpoints]="{'850px' : '90vw'}" [draggable]="false" [resizable]="false" [(visible)]="Modal"
        (onHide)="onDialogClose()">
        <form [formGroup]="regularMemberApprovalForm" (ngSubmit)="approveMemberById()" #regularverify="ngForm">
            <div class="upload-dialog">
                <!-- approve form -->
                <div *ngIf="showApproveForm">
                    <div class="file-upload">
                        <div class="row">
                            <div class="col-12 mt-2">
                                <label style="margin-bottom: 5px;">Date
                                    of Membership
                                    Committee Meeting<span class="required">*</span></label>
                                <p-calendar formControlName="meetingdate" readonly showClear="true" appendTo="body"
                                    dateFormat="dd/mm/yy" placeholder="DD/MM/YYYY" [showIcon]="true" readonly
                                    [inline]="true">
                                </p-calendar>
                                <div *ngIf="regularverify.submitted &&
                                            function.meetingdate.errors?.required
                                            ||
                                            function.meetingdate.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="function.meetingdate.errors?.required">
                                        Date of Membership
                                        Committee Meeting
                                        is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- reject form -->
                <div *ngIf="showRejectForm">
                    <div class="file-reject">
                        <div class="row">
                            <div class="col-12">
                                <label for="">Date of Membership
                                    Committee Meeting<span class="required">*</span></label>
                                <p-calendar formControlName="meetingdatereject" readonly showClear="true"
                                    dateFormat="dd/mm/yy" placeholder="DD/MM/YYYY" [showIcon]="true" readonly
                                    [inline]="true"></p-calendar>
                                <div *ngIf="regularverify.submitted &&
                                            function.meetingdatereject.errors?.required
                                            ||
                                            function.meetingdatereject.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="function.meetingdatereject.errors?.required">
                                        Date of Membership
                                        Committee Meeting
                                        is
                                        required
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <label class="mb-3" for="">
                                    Reason<span class="required">*</span></label>
                                <textarea type="text" placeholder="Reason" formControlName="reason" class="form-control"
                                    [class.is-invalid]="(regularverify.submittedReject
                                            &&
                                            regularMemberApprovalForm.get('reason')?.invalid)
                                            ||
                                            regularMemberApprovalForm.get('reason')?.invalid
                                            &&
                                            regularMemberApprovalForm.get('reason').touched">
                                        </textarea>
                                <div *ngIf="regularverify.submitted &&
                                            function.reason.errors?.required ||
                                            function.reason.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="function.reason.errors?.required">
                                        Reason
                                        is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="add-listings-btn mt-3">
                    <p-button type="submit" label="Save" pRipple></p-button>
                </div>
            </div>
        </form>
    </p-dialog>
    <!-- Dialog when proposed by & seconded by members not approved application for user -->
    <p-dialog p-dialog header="Alert" [modal]="true" [style]="{width:
        '42vw'}" [breakpoints]="{'850px' : '90vw'}" [draggable]="false" [resizable]="false" [(visible)]="AlertDialog"
        (onHide)="onDialogClose()">
        <p style="color : #050d2b; padding: 20px; font-weight: 500;">The request made by the user cannot be approved
            unless it is accepted by both the member who proposed it and
            the member who seconded it.</p>
    </p-dialog>
    <!-- ***************************************************************************************************************** -->
    <!----------------------------------------------------------- view dialog --------------------------------------------------------->
    <!-- ***************************************************************************************************************** -->
    <p-dialog header="Regular Membership Form Details" [(visible)]="displayMaximizable" [modal]="true"
        [style]="{ width: '90vw' }" [draggable]="false" [resizable]="false">
        <!-- Start -->
        <ng-container *ngFor="let data of selectedMemberData">
            <div class="row">
                <div class="col-lg-12 mt-2">
                    <div class="my-profile-box">
                        <h3>Basic Informations</h3>
                        <form>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Name of the Applicant Firm/Company :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.company_name | titlecase}}" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Main Nature of Business :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nob?.name }}" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Member Classification :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.member_classification?.name}}" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Constitution of the Applicant :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.applicant_constitution
                                                    }}" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>GST Number :</label>
                                        <input type="text" readonly class="form-control" value="{{ data?.gst_number
                                                    }}" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>CIN :</label>
                                        <input type="text" readonly class="form-control" value="{{ data?.cin_number
                                                    }}" />
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Office Telephone :</label>
                                        <input type="text" value="{{
                                                    data?.office_telephone }}" class="form-control" readonly />
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Residental Telephone :</label>
                                        <input type="text" value="{{
                                                    data?.residential_telephone
                                                    }}" class="form-control" readonly />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Average Number of
                                            Fulltime Employee :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.avg_no_of_fulltime_employee
                                                    }}" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="my-profile-box">
                        <h3>Registered/Main/Head Office Address</h3>
                        <form>
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <label>Address :</label>
                                        <input type="text" readonly class="form-control" value="{{data?.company_address[0]?.address
                                                    }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>State :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.company_address[0]?.state}}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>City :</label>
                                        <input type="text" readonly class="form-control" value="{{data?.company_address[0]?.city
                                                    }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Pin Code :</label>
                                        <input type="text" readonly class="form-control" value="{{data?.company_address[0]?.pin_code
                                                    }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Website :</label>
                                        <input type="text" readonly class="form-control" value="{{ data?.website }}" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Email :</label>
                                        <input type="text" readonly class="form-control" value="{{ data?.email }}" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="my-profile-box">
                        <h3>Main Unit Address</h3>
                        <form>
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <label>Address :</label>
                                        <input type="text" readonly class="form-control" value="{{data?.company_address[1]?.address
                                                    }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>State :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.company_address[1]?.state}}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>City :</label>
                                        <input type="text" readonly class="form-control" value="{{data?.company_address[1]?.city
                                                    }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Pin Code :</label>
                                        <input type="text" readonly class="form-control" value="{{data?.company_address[1]?.pin_code
                                                    }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Website :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{ data?.main_website }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Email :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{ data?.main_email }}" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="col-12">
                    <div class="my-profile-box">
                        <h3>Company Details</h3>
                        <form>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Capital Investment :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                    data?.capital_investment}}" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Registration Number :</label>
                                        <input type="text" readonly class="form-control" value="{{data?.registration_number
                                    }}" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>About :</label>
                                        <input type="text" readonly class="form-control" value="{{ data?.about }}" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Sales Turnover :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.turn_over?.component}}" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Member Category :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.member_category?.name}}" />
                                    </div>
                                </div>

                                <div class="col-xl-4 col-lg-12
                            col-md-12">
                                    <div class="form-group">
                                        <label>Date of Establishment :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                    data?.establishment_date |
                                    date : 'dd/MM/yyyy' }}" />
                                    </div>
                                </div>

                                <!-- <div class="col-lg-4 col-md-12">
                                        <div class="form-group">
                                            <label>Is the Applicant Registered with UCCI Under :</label>
                                            <input type="text" readonly class="form-control"
                                                value="{{getAppRegistered(data?.applicant_registered)}}" />
                                        </div>
                                    </div> -->
                                <div class="col-lg-4 col-md-12">
                                    <div class="form-group">
                                        <label>Registered by :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.registered_by}}" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Company Logo :</label>
                                        <div>
                                            <img [src]="getImage(data?.media,'logo')" style="height: 70px;
                                                        width:70px;">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="form-group">
                                        <label>Other Information :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.other_information ? data?.other_information : '-'}}" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="form-group">
                                        <label>Nominee a Member of any Other Associations :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.other_profession ? data?.other_profession : '-'}}" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


                <div class="col-lg-6 col-md-6">
                    <div class="my-profile-box">
                        <h3>Primary Nominee Details</h3>
                        <form>
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <label>Name :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nominee1?.name }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Designation :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nominee1?.designation
                                                    }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>PAN :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nominee1?.pan_number
                                                    }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Date of Birth :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nominee1?.date_of_birth|date
                                                    : 'dd/MM/yyyy'
                                                    }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Email :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nominee1?.email }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Office Phone :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nominee1?.office_phone
                                                    }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Mobile :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nominee1?.mobile }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Address :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.nominee1_address?.address}}" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>State :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.nominee1_address?.state}}" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>City :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.nominee1_address?.city}}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Pincode :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.nominee1_address?.pin_code}}" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Department :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.nominee1?.department}}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Industry Area :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nominee1?.industry_area?.name}}" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Biodata :</label>
                                        <textarea type="text" readonly class="form-control" value="{{
                                                    data?.nominee1?.biodata }}">
                                                </textarea>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <label>Image :</label>
                                        <ng-container *ngIf="data?.nominee1?.media">
                                            <img [src]="getImage(data?.nominee1?.media,'profile1')" style="height: 70px;
                                                    width:70px;">
                                        </ng-container>
                                        <ng-container *ngIf="!data?.nominee1?.media">
                                            <img src="../../../../../../assets/img/imageNotFound.png" style="height: 70px;
                                                        width:70px;">
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                    <div class="form-group">
                                        <label>Preferred Communication :</label>
                                        <ul>
                                            <!-- <li *ngFor="let item of
                                                data?.nominee1?.preferred_communication
                                                | jsonParse">
                                                {{item}}</li> -->
                                                <li *ngFor="let item of
                                               noDupli(data?.nominee1?.preferred_communication) | jsonParse">
                                                {{item}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                    <div class="form-group">
                                        <label>Interest Area :</label>
                                        <ul style="max-height: 100px; overflow: auto;">
                                            <li *ngFor="let item of
                                                    data?.nominee1_interest">
                                                {{item.interest_area}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Other Information :</label>
                                        <textarea type="text" readonly class="form-control"
                                            value="{{
                                                    data?.nominee1?.other_profession ? data?.nominee1?.other_profession : '-' }}">
                                                </textarea>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="my-profile-box">
                        <h3>Secondary Nominee Details</h3>
                        <form>
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <label>Name :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nominee2?.name }}" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Designation :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nominee2?.designation
                                                    }}" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                    <div class="form-group">
                                        <label>PAN :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                data?.nominee2?.pan_number
                                                }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Date of Birth :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                data?.nominee2?.date_of_birth|date
                                                : 'dd/MM/yyyy'
                                                }}" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Email :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nominee2?.email }}" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                        col-md-12">
                                    <div class="form-group">
                                        <label>Office Phone :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                data?.nominee2?.office_phone
                                                }}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Mobile :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nominee2?.mobile }}" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Address :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.nominee2_address?.address}}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>State :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.nominee2_address?.state}}" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>City :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.nominee2_address?.city}}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Pincode :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.nominee2_address?.pin_code}}" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Department :</label>
                                        <input type="text" readonly class="form-control"
                                            value="{{data?.nominee2?.department}}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Industry Area :</label>
                                        <input type="text" readonly class="form-control" value="{{
                                                    data?.nominee2?.industry_area?.name}}" />
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-6
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Biodata :</label>
                                        <textarea type="text" readonly class="form-control" value="{{
                                                        data?.nominee2?.biodata }}">
                                                    </textarea>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <label>Image :</label>
                                        <ng-container *ngIf="data?.nominee2?.media">
                                            <img [src]="getImage(data?.nominee2?.media,'profile2')" style="height: 70px;
                                                    width:70px;">
                                        </ng-container>
                                        <ng-container *ngIf="!data?.nominee2?.media">
                                            <img src="../../../../../../assets/img/imageNotFound.png" style="height: 70px;
                                                        width:70px;">
                                        </ng-container>
                                    </div>
                                </div>

                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Preferred Communication :</label>
                                        <ul>
                                            <!-- <li *ngFor="let item of
                                                        data?.nominee2?.preferred_communication
                                                        | jsonParse">
                                                {{item}}</li> -->
                                                <li *ngFor="let item of
                                                noDupli( data?.nominee2?.preferred_communication) | jsonParse">
                                                 {{item}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Interest Area :</label>
                                        <ul style="max-height: 100px; overflow: auto;">
                                            <li *ngFor="let item of
                                                        data?.nominee2_interest">
                                                {{item?.interest_area}}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <label>Other Information :</label>
                                        <textarea type="text" readonly class="form-control"
                                            value="{{
                                                        data?.nominee2?.other_profession ? data?.nominee2?.other_profession : '-' }}">
                                                    </textarea>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-12 col-md-6" *ngIf="getAPIData.representatives?.length > 0">
                    <div class="my-profile-box">
                        <h3>Signed on Behalf of the Applicant by its Authorised Representative</h3>
                        <form>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group" *ngIf="getAPIData.representatives">
                                        <label>Representative Name<span class="required">*</span>:</label>
                                        <input type="text" class="form-control" disabled
                                            [value]="getAPIData.representatives[0]?.representative_name ? getAPIData.representatives[0]?.representative_name : '-'">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12" *ngIf="getAPIData.representatives">
                                    <div class="form-group">
                                        <label>Representative Designation<span class="required">*</span>:</label>
                                        <input type="text" class="form-control" disabled
                                            [value]="getAPIData.representatives[0]?.representative_designation ? getAPIData.representatives[0]?.representative_designation : '-'">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Date<span class="required">*</span>:</label>
                                        <input type="text" class="form-control" disabled
                                            [value]="getAPIData.representatives[0]?.signed_on  ?    (getAPIData.representatives[0]?.signed_on | date:'dd/MM/YYYY') : '-'">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Place<span class="required">*</span>:</label>
                                        <input type="text" class="form-control" disabled
                                            value="{{getAPIData?.representatives[0]?.place}}">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-12 col-md-6">
                    <div class="my-profile-box">
                        <h3>Verification Details</h3>
                        <form>
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Form Received On<span class="required">*</span>:</label>
                                        <input type="text" class="form-control" disabled
                                            [value]="getAPIData.form_received_on ? (getAPIData.form_received_on | date:'dd/MM/YYYY') : '-'">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Form Checked for
                                            Completeness On<span class="required">*</span>:</label>
                                        <input type="text" class="form-control" disabled
                                            [value]="getAPIData.form_checked_on ? (getAPIData.form_checked_on | date:'dd/MM/YYYY') : '-'">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Checked By<span class="required">*</span>:</label>
                                        <input value="{{localStorage.username}}" readonly class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Payment Mode</label>
                                        <input value="{{getAPIData.mode_of_payment}}" readonly class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Gaps in Attachments /
                                            Applications<span class="required">*</span>:</label>
                                        <input type="text" class="form-control" maxlength="5"
                                            placeholder="Gaps in Attachments / Applications" disabled
                                            [value]="getAPIData.gaps_in_application ? getAPIData.gaps_in_application : '-'"
                                            [maxLength]="50">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Date Application Found
                                            Complete
                                            On<span class="required">*</span>:</label>
                                        <input type="text" class="form-control" disabled
                                            [value]="getAPIData.application_found_complete_on ? (getAPIData.application_found_complete_on | date:'dd/MM/YYYY'): '-'">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Annual Turnover</label>
                                        <input readonly class="form-control"
                                            value="{{getAPIData.turn_over?.component}}">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <table>
                                        <tr>
                                            <th>Fee Applicable</th>
                                            <th>Amount</th>
                                            <th>GST</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr *ngIf="admissionFee.length > 0">
                                            <td>Admission Fee</td>
                                            <td>
                                                {{admissionFee[0].membership_fee
                                                | indianCurrency}}
                                            </td>
                                            <td>
                                                {{admissionFee[0].tax_percentage}}%
                                            </td>
                                            <td>{{admissionFeeTotal |
                                                indianCurrency}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Compulsory Capital Contribution Fee</td>
                                            <td>
                                                {{capitalFee[0]?.membership_fee
                                                | indianCurrency}}
                                            </td>
                                            <td>{{capitalFee[0]?.tax_percentage}}%
                                            </td>
                                            <td>{{capitalFeeTotal |
                                                indianCurrency}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Contribution for Programme Fee</td>
                                            <td>{{programFee[0]?.membership_fee
                                                |
                                                indianCurrency}}
                                            </td>
                                            <td>{{programFee[0]?.tax_percentage}}%
                                            </td>
                                            <td>{{programFeeTotal |
                                                indianCurrency}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Annual Membership Renewal Fee</td>
                                            <td>{{annual_fee.membership_fee
                                                | indianCurrency}}
                                            </td>
                                            <td>{{annual_fee.tax_percentage}}%
                                            </td>
                                            <td>{{annualFeeTotal |
                                                indianCurrency}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style="border-right:
                                                        none;" colspan="3">
                                                Sub total
                                            </th>
                                            <td style="border-left: none
                                                        ; color: #000;"><b>{{total
                                                    | indianCurrency}}</b>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <ng-container *ngIf="getAPIData?.mode_of_payment
                                            == 'offline'">
                                    <div class="col-lg-4 col-md-6
                                                col-12">
                                        <div class="form-group">
                                            <label>Payment By</label>
                                            <input class="form-control" disabled
                                                [(value)]="getAPIData.offline_payments.paid_by" type="text"
                                                class="form-control" maxlength="6">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6
                                                col-12">
                                        <div class="form-group">
                                            <label>Cheque Number</label>
                                            <input class="form-control" disabled
                                                [(value)]="getAPIData.offline_payments.cheque_no" type="text"
                                                class="form-control" placeholder="Cheque Number" maxlength="6">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6
                                                col-12">
                                        <div class="form-group">
                                            <label>Date</label>
                                            <input class="form-control"
                                                value="{{getAPIData.offline_payments.updated_at | date:'dd/MM/YYYY'}}"
                                                type="text" class="form-control" disabled>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6
                                                col-12">
                                        <div class="form-group">
                                            <label>Bank Name</label>
                                            <input class="form-control"
                                                [(value)]="getAPIData.offline_payments.bank_name" type="text"
                                                class="form-control" placeholder="Bank Name" disabled>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Category of Membership:</label>
                                    </div>
                                    <table>
                                        <tr>
                                            <th>Category</th>
                                            <th>Classifications</th>
                                            <th>Existing nos</th>
                                            <th>Maximum Members Alllowed</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{getAPIData?.member_category?.name}}
                                            </td>
                                            <td>
                                                {{getAPIData?.member_classification?.name}}
                                            </td>
                                            <td>
                                                {{existing_nos | number: '1.0-0'}}
                                            </td>
                                            <td>{{max_member_allowed | number: '1.0-0'}}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <ng-container *ngIf="getAPIData.representatives?.length > 0">
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>Proposed by M/s</label>
                                            <input readonly class="form-control"
                                                value="{{getAPIData.representatives[0]?.proposed_by_organisation}}">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>UCCI Membership No.</label>
                                            <input readonly class="form-control"
                                                value="{{getAPIData.representatives[0]?.proposed_by_membership_no}}">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>Designation</label>
                                            <input readonly class="form-control"
                                                value="{{getAPIData.representatives[0]?.proposed_by_designation}}">
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>(Member Since)</label>
                                        <input readonly class="form-control"
                                        value="{{getAPIData.representatives[0]?.primary_proposed_by?.member_since | date: 'yyyy'}}">
                                        
                                           <!-- value="{{getAPIData.primary_member_since}}" -->
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <label>Nominee Name</label>
                                        <input readonly class="form-control"
                                            value="{{getAPIData.representatives[0]?.proposed_by_nominee}}">
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="form-group status">
                                        <label>Status</label>
                                        <ng-container
                                            *ngIf="getAPIData.representatives[0]?.proposed_by_status == 'Approved'">
                                            <span class="approved">
                                                {{getAPIData.representatives[0]?.proposed_by_status ?
                                                getAPIData.representatives[0]?.proposed_by_status :
                                                '-'}}
                                            </span>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="getAPIData.representatives[0]?.proposed_by_status == 'Rejected'">
                                            <span class="rejected">
                                                {{getAPIData.representatives[0]?.proposed_by_status ?
                                                getAPIData.representatives[0]?.proposed_by_status :
                                                '-'}}
                                            </span>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="getAPIData.representatives[0]?.proposed_by_status == 'Pending'">
                                            <span class="pending">
                                                {{getAPIData.representatives[0]?.proposed_by_status ?
                                                getAPIData.representatives[0]?.proposed_by_status :
                                                '-'}}
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>Seconded by M/s</label>
                                            <input readonly class="form-control"
                                                value="{{getAPIData.representatives[0]?.seconded_by_organisation}}">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>UCCI Membership No.</label>
                                            <input readonly class="form-control"
                                                value="{{getAPIData.representatives[0]?.seconded_by_membership_no}}">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>Designation</label>
                                            <input readonly class="form-control"
                                                value="{{getAPIData.representatives[0]?.seconded_by_designation}}">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>(Member Since)</label>
                                            <input readonly class="form-control"
                                            value="{{getAPIData.representatives[0]?.secondary_proposed_by?.member_since | date: 'yyyy'}}"> 
                                               
                                                <!-- value="{{getAPIData.seconded_member_since}}" -->

                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>Nominee Name</label>
                                            <input readonly class="form-control"
                                                value="{{getAPIData?.representatives[0]?.seconded_by_nominee}}">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6 col-12">
                                        <div class="form-group status">
                                            <label>Status</label>
                                            <ng-container
                                                *ngIf="getAPIData.representatives[0]?.seconded_by_status == 'Approved'">
                                                <span class="approved">
                                                    {{getAPIData.representatives[0]?.seconded_by_status ?
                                                    getAPIData.representatives[0]?.seconded_by_status :
                                                    '-'}}
                                                </span>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="getAPIData.representatives[0]?.seconded_by_status == 'Rejected'">
                                                <span class="rejected">
                                                    {{getAPIData.representatives[0]?.seconded_by_status ?
                                                    getAPIData.representatives[0]?.seconded_by_status :
                                                    '-'}}
                                                </span>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="getAPIData.representatives[0]?.seconded_by_status == 'Pending'">
                                                <span class="pending">
                                                    {{getAPIData.representatives[0]?.seconded_by_status ?
                                                    getAPIData.representatives[0]?.seconded_by_status :
                                                    '-'}}
                                                </span>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <ng-container *ngFor="let img of data?.enclosures">
                                            <div class="col-lg-6 col-md-12">
                                                <div class="form-group">
                                                    <label>{{img.enclosures_title}}</label>
                                                    <ng-container
                                                        *ngIf="getExtension(img.document); then firstblock else secondblock">
                                                    </ng-container>
                                                    <ng-template #firstblock>
                                                        <a [href]="getImage(img.document , 'enclosure_documents')"
                                                            style=" font-size: 0.8rem;
                                                        color: var(--mainColor);
                                                        font-weight: 700; text-decoration: underline;" target="_blank"
                                                            [download]="getImage(img.document , 'enclosure_documents')">{{img.enclosures_title}}</a>
                                                    </ng-template>
                                                    <ng-template #secondblock>
                                                        <img [src]="getImage(img.document , 'enclosure_documents')"
                                                            style="height: 70px;
                                                        width:70px;">
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="displayMaximizable=
                    false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
    </p-dialog>
    <!-- ***************************************************************************************************************** -->
    <!----------------------------------------------------------- verification dialog --------------------------------------------------------->
    <!-- ***************************************************************************************************************** -->
    <!-- <ng-template #body> -->
    <p-dialog header="Verification" [(visible)]="displayVerificationDialog" [modal]="true" [style]="{ width: '60vw' }"
        [breakpoints]="{'850px' : '90vw'}" [draggable]="false" [resizable]="false" #verificationDialog id="backToTop">
        <ng-container *ngFor="let data of details">
            <form [formGroup]="verificationForm" (ngSubmit)="verifyData(verify)" #verify="ngForm">
                <div class="add-listings-box">
                    <h3>Basic Informations</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-12 mt-2">
                            <div class="form-group">
                                <label>Form Received On<span class="required">*</span>:</label>
                                <p-calendar formControlName="form_received_on" [disabled]="true" showClear="true"
                                    dateFormat="dd/mm/yy" placeholder="DD/MM/YYYY" [(ngModel)]="date1" [showIcon]="true"
                                    readonly></p-calendar>
                                <div *ngIf="verify.submitted &&
                                            f.form_received_on.errors?.required
                                            ||
                                            f.form_received_on.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="f.form_received_on.errors?.required">
                                        Date is Required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-group">
                                <label>Form Checked for Completeness On<span class="required">*</span>:</label>
                                <p-calendar formControlName="form_checked_on" showClear="true" [maxDate]="today"
                                    [minDate]="formRecivedDate" [appendTo]="verificationDialog" dateFormat="dd/mm/yy"
                                    placeholder="DD/MM/YYYY" [showIcon]="true" readonly>
                                </p-calendar>
                                <div *ngIf="verify.submitted &&
                                            f.form_checked_on.errors?.required
                                            ||
                                            f.form_checked_on.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="f.form_checked_on.errors?.required">
                                        Form Checked for Completeness On
                                        is
                                        Required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-group">
                                <label>Checked By<span class="required">*</span>:</label>
                                <input value="{{localStorage.username}}" readonly class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-group">
                                <label>Payment Mode</label>
                                <input value="{{getAPIData?.mode_of_payment}}" readonly class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-group">
                                <label>Gaps in Attachments /
                                    Applications<span class="required">*</span>:</label>
                                <input [class.is-invalid]="(verify.submitted
                                            &&
                                            verificationForm.get('gaps_in_application')?.invalid)
                                            ||
                                            verificationForm.get('gaps_in_application')?.invalid
                                            &&
                                            verificationForm.get('gaps_in_application').touched" type="text"
                                    class="form-control" placeholder="Gaps in Attachments / Applications"
                                    formControlName="gaps_in_application" [maxLength]="50">
                                <div *ngIf="verify.submitted && f.gaps_in_application.errors?.required || f.gaps_in_application.touched"
                                    class="alert alert-danger">
                                    <div *ngIf="f.gaps_in_application.errors?.required"> Gaps in Attachments /
                                        Applications is Required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-group">
                                <label>Date Application Found Complete
                                    On<span class="required">*</span>:</label>
                                <p-calendar formControlName="application_found_complete_on" showClear="true"
                                    dateFormat="dd/mm/yy" [appendTo]="verificationDialog" placeholder="DD/MM/YYYY"
                                    [maxDate]="today" [minDate]="formRecivedDate" [showIcon]="true"
                                    readonly></p-calendar>
                                <div *ngIf="verify.submitted &&
                                            f.application_found_complete_on.errors?.required
                                            ||
                                            f.application_found_complete_on.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="f.application_found_complete_on.errors?.required">
                                        Date Application Found Complete
                                        On
                                        is Required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-group">
                                <label>Annual Turnover</label>
                                <input readonly class="form-control" value="{{getAPIData?.turn_over?.component}}">
                            </div>
                        </div>
                        <div class="col-12">
                            <table>
                                <tr>
                                    <th>Fee Applicable</th>
                                    <th>Amount</th>
                                    <th>GST</th>
                                    <th>Total</th>
                                </tr>
                                <tr *ngIf="admissionFee.length > 0">
                                    <td>Admission Fee</td>
                                    <td>
                                        {{admissionFee[0]?.membership_fee
                                        | indianCurrency}}
                                    </td>
                                    <td>
                                        {{admissionFee[0].tax_percentage}}%
                                    </td>
                                    <td>{{admissionFeeTotal |
                                        indianCurrency}}
                                    </td>
                                </tr>
                                <tr *ngIf="capitalFee.length > 0">
                                    <td>Compulsory Capital Contribution Fee</td>
                                    <td>
                                        {{capitalFee[0]?.membership_fee
                                        | indianCurrency}}
                                    </td>
                                    <td>{{capitalFee[0].tax_percentage}}%
                                    </td>
                                    <td>{{capitalFeeTotal |
                                        indianCurrency}}
                                    </td>
                                </tr>
                                <tr *ngIf="programFee.length > 0">
                                    <td>Contribution for Programme Fee</td>
                                    <td>{{programFee[0].membership_fee |
                                        indianCurrency}}
                                    </td>
                                    <td>{{programFee[0].tax_percentage}}%
                                    </td>
                                    <td>{{programFeeTotal |
                                        indianCurrency}}
                                    </td>
                                </tr>
                                <tr *ngIf="annual_fee.length > 0">
                                    <td>Annual Membership Renewal Fee</td>
                                    <td>{{annual_fee.membership_fee |
                                        indianCurrency}}
                                    </td>
                                    <td>{{annual_fee.tax_percentage}}%
                                    </td>
                                    <td>{{annualFeeTotal |
                                        indianCurrency}}
                                    </td>
                                </tr>
                                <tr>
                                    <th colspan="3" style="border-right:
                                                none!important;">
                                        Sum total
                                    </th>
                                    <td style="color: #000!important;
                                                border-left: none!important;"><b>{{total
                                            | indianCurrency}}</b>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <ng-container *ngIf="getAPIData?.mode_of_payment
                                    == 'offline'">
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Payment By</label>
                                    <p-dropdown [options]="paymentMode" optionValue="value" formControlName="paid_by"
                                        placeholder="Select Option" optionLabel="label"
                                        (onChange)="onPaymentMethodeSelect()">
                                    </p-dropdown>
                                    <div class="invalid-feedback">Payment By is Required</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Cheque/Transaction No.</label>
                                    <input class="form-control" [class.is-invalid]="(verify.submitted
                                                &&
                                                verificationForm.get('cheque_no')?.invalid)
                                                ||
                                                verificationForm.get('cheque_no')?.invalid
                                                &&
                                                verificationForm.get('cheque_no').touched" type="text"
                                        class="form-control" placeholder="Cheque/Transaction No"
                                        formControlName="cheque_no" required maxlength="6">

                                    <div class="invalid-feedback">Cheque/Transaction No.
                                        is Required</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Transaction Date</label>
                                    <!-- [maxDate]="chequeMaxDate" [minDate]="chequeMinDate" -->
                                    <p-calendar formControlName="cheque_date" showClear="true" dateFormat="dd/mm/yy"
                                        placeholder="DD/MM/YYYY" required [showIcon]="true"
                                        [disabled]="chequeDateState"></p-calendar>
                                    <div *ngIf="verify.submitted &&
                                                f.cheque_date.errors?.required
                                                ||
                                                f.cheque_date.touched" class="alert
                                                alert-danger">
                                        <div *ngIf="f.cheque_date.errors?.required">
                                            Transaction Date is Required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="form-group">
                                    <label>Bank Name</label>
                                    <input class="form-control" type="text" class="form-control" placeholder="Bank Name"
                                        formControlName="bank_name">
                                </div>
                            </div>
                        </ng-container>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Category of Membership:</label>
                            </div>
                            <table>
                                <tr>
                                    <th>Category</th>
                                    <th>Classifications</th>
                                    <th>Existing NOS</th>
                                    <th>Maximum Members Alllowed</th>
                                </tr>
                                <tr>
                                    <td>
                                        {{getAPIData?.member_category?.name}}
                                    </td>
                                    <td>
                                        {{getAPIData?.member_classification?.name}}
                                    </td>
                                    <td>
                                        {{existing_nos | number: '1.0-0'}}
                                    </td>
                                    <td>{{max_member_allowed | number: '1.0-0'}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-group">
                                <label>Proposed by M/s</label>
                                <input readonly class="form-control"
                                    [value]="getAPIData.representatives[0]?.proposed_by_organisation ? getAPIData.representatives[0]?.proposed_by_organisation : '-'">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-group">
                                <label>(Member Since)</label>
                                <input readonly class="form-control"
                                    value="{{(getAPIData.representatives[0]?.primary_proposed_by?.member_since | date: 'yyyy') ? (getAPIData.representatives[0]?.primary_proposed_by?.member_since | date: 'yyyy') : '-'}}">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Nominee Name</label>
                                <input readonly class="form-control"
                                    [value]="getAPIData?.representatives[0]?.proposed_by_nominee ? getAPIData?.representatives[0]?.proposed_by_nominee : '-'">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-group">
                                <label>Seconded by M/s</label>
                                <input readonly class="form-control"
                                    [value]="getAPIData?.representatives[0]?.seconded_by_organisation ? getAPIData?.representatives[0]?.seconded_by_organisation : '-'">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-group">
                                <label>(Member Since)</label>
                                <input readonly class="form-control"
                                    value="{{(getAPIData.representatives[0]?.secondary_proposed_by?.member_since | date: 'yyyy') ? (getAPIData.representatives[0]?.secondary_proposed_by?.member_since | date: 'yyyy') : '-'}}">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Nominee Name</label>
                                <input readonly class="form-control"
                                    [value]="getAPIData?.representatives[0]?.seconded_by_nominee ?  getAPIData?.representatives[0]?.seconded_by_nominee : '-'">
                            </div>
                        </div>
                        <div class="add-listings-btn">
                            <button type="submit" class="submit_btn" (click)="goToTop()">Verify</button>
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>
    </p-dialog>

</section>
<ng-container *ngIf="successState">
    <app-success [statusMsg]="message"></app-success>
</ng-container>
<ng-container *ngIf="payStatus">
    <app-payment-form [RecivedData]="paymentResponse"></app-payment-form>
</ng-container>
<p-dialog header="Transaction Details" [(visible)]="paymentDialog" [modal]="true" [style]="{ width: '62vw' }"
    [breakpoints]="{'850px' : '90vw'}" [draggable]="false" [resizable]="false" #verificationDialog id="backToTop">
    <form [formGroup]="paymentForm" (ngSubmit)="submit()" #payment="ngForm">
        <div class="add-listings-box">
            <h3>Registration Fee</h3>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Payment By<span class="required">*</span>:</label>
                        <p-dropdown [options]="paymentMode" optionValue="value" formControlName="paid_by"
                            placeholder="Select Option" optionLabel="label"
                            [styleClass]="(payment.submitted && paymentForm.get('paid_by')?.invalid) || paymentForm.get('paid_by')?.invalid && paymentForm.get('paid_by').touched ? 'error' : ''">
                        </p-dropdown>
                        <div *ngIf="payment.submitted && f.paid_by.errors?.required || f.paid_by.touched"
                            class="alert alert-danger">
                            <div *ngIf="f.paid_by.errors?.required">Payment By is Required</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Cheque/Transaction No.</label>
                        <input class="form-control" type="text" class="form-control" placeholder="Cheque/Transaction No"
                            formControlName="cheque_no">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Transaction Date<span class="required">*</span>:</label>
                        <p-calendar formControlName="payment_date" showClear="true" dateFormat="dd/mm/yy"
                        placeholder="DD/MM/YYYY" required [showIcon]="true" appendTo="body"></p-calendar>
                    
                        <!-- <div *ngIf="payment.submitted &&
                            f.payment_date.errors?.required
                            ||
                            f.payment_date.touched" class="alert
                            alert-danger">
                            <div *ngIf="f.payment_date.errors?.required">
                                Transaction Date is Required
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Cheque Date</label>
                        <p-calendar formControlName="cheque_date" showClear="true" dateFormat="dd/mm/yy"
                            placeholder="DD/MM/YYYY" [showIcon]="true" appendTo="body"></p-calendar>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label>Bank Name:</label>
                        <input class="form-control" type="text" class="form-control" placeholder="Bank Name"
                            formControlName="bank_name">
                        <!-- <div *ngIf="payment.submitted &&
                                f.bank_name.errors?.required ||
                                f.bank_name.touched" class="alert alert-danger">
                            <div *ngIf="f.bank_name.errors?.required">
                                Bank Name is Required
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="add-listings-btn">
                    <button type="submit" class="submit_btn">Submit</button>
                </div>
            </div>
          
        </div>
    </form>
</p-dialog>