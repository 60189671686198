import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { EventService } from 'src/app/services/event.service';
import { NewsAndEventsServiceService } from 'src/app/services/news-and-events-service.service';
import { WebsiteService } from 'src/app/services/website.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [MessageService],
})
export class HomeComponent implements OnInit {
  contactInfo: any = {};
  isAwards:boolean = false;
  @Input() pageData: any;
  imageUCCIAwardsUrl: any;
  contactCardData: any = [
    {
      icon: 'assets/img/ucci-images/icon1.png',
      heading: 'Udaipur Office',
      content1:
        'Chamber Bhawan, Mewar Industrial Area , Madri ,  Udaipur-313003',
      content2: '',
    },
    {
      icon: 'assets/img/ucci-images/icon2.png',
      heading: 'Email Us',
      content1: 'info@ucciudaipur.com',
      content2: 'uccisec@ucciudaipur.com',
    },
    {
      icon: 'assets/img/ucci-images/icon3.png',
      heading: 'Call Us',
      content1: '+91-9351917002',
      content2: '+91-9351917008',
    },
  ];

  bannerImages = [
    { image: 'assets/img/ucci-images/image2.jpg' },
    { image: 'assets/img/ucci-images/image2.jpg' },
    { image: 'assets/img/ucci-images/image2.jpg' },
    { image: 'assets/img/ucci-images/image2.jpg' },
    { image: 'assets/img/ucci-images/image2.jpg' },
  ];

  events = [
    {
      image: 'assets/img/ucci-images/event-image.png',
      date: '15 March - 2021 / Awards',
      heading: 'Solar Rooftop Conference',
      content:
        'Lorem Ipsum er ganske enkelt fyldtekst fra print- og typografiindustrien. Lorem Ipsum har været standard fyldtekst siden 1500-tallet, hvor en ukendt trykker sammensatte en tilfældig spalte for at trykke en bog til sammenligning af forskellige skrifttyper.',
    },
    {
      image: 'assets/img/ucci-images/event-image.png',
      date: '15 March - 2021 / Awards',
      heading: 'Solar Rooftop Conference',
      content:
        'Lorem Ipsum er ganske enkelt fyldtekst fra print- og typografiindustrien. Lorem Ipsum har været standard fyldtekst siden 1500-tallet, hvor en ukendt trykker sammensatte en tilfældig spalte for at trykke en bog til sammenligning af forskellige skrifttyper.',
    },
    {
      image: 'assets/img/ucci-images/event-image.png',
      date: '15 March - 2021 / Awards',
      heading: 'Solar Rooftop Conference',
      content:
        'Lorem Ipsum er ganske enkelt fyldtekst fra print- og typografiindustrien. Lorem Ipsum har været standard fyldtekst siden 1500-tallet, hvor en ukendt trykker sammensatte en tilfældig spalte for at trykke en bog til sammenligning af forskellige skrifttyper.',
    },
  ];

  data: any = {};
  news: any;
  currentEvents:any=[];
  afdPages:any;

  constructor(
    private route: Router,
    private websiteService: WebsiteService,
    private messageService: MessageService,
    private eventService: EventService,
    private spinner: NgxSpinnerService,
    private notificationService : NewsAndEventsServiceService
  ) {}

  ngOnInit() {
    this.spinner.hide();
    if (this.pageData?.page_json) {
      this.data = JSON.parse(this.pageData?.page_json);
    }
    this.eventService.getEventsList().subscribe((res: any) => {
      console.log(res);


      const currentDate = new Date();

this.currentEvents = res?.data.filter((event: any) => {
  const eventDate = new Date(event.date);
  return eventDate >= currentDate;
});
console.log(this.currentEvents);

      this.data.section6.event = [];
      if(this.currentEvents.length > 2) {
        this.data.section6.event = this.currentEvents;
      } 
      else if(this.currentEvents.length != 0) {
        this.data.section6.event = this.currentEvents;
        for( let i = 0; i < 3; i++) {
          if(this.data.section6.event.length != 3) {
            this.data.section6.event.push({})
          }
          else {
            break;
          }
        }
      }
      else {
        this.data.section6.event = []
      }
    });
    console.log(this.data);
    this.notificationService.getNotifications().subscribe((res:any) => {
      console.log(res);
      this.news = res?.data?.filter(res => res?.news_type == 'Government News')
      console.log(this.data, this.news);
      if(this.news > 2) {
        // this.data.section6.event = res?.data;
      } 
      else if(this.news != 0) {
        // this.data.section6.event = res?.data;
        for( let i = 0; i < 3; i++) {
          if(this.news != 3) {
            this.news.push({})
          }
          else {
            break;
          }
        }
      }
      else {
        this.news = []
      }
    })
    this.getAfdData();
  }

  responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '950px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  //store contact us info
  contactUs(form: NgForm) {
    console.log(form.value, form.valid);
    if (form.valid) {
      this.websiteService
        .submitContactInfo(this.contactInfo)
        .then((res: any) => {
          console.log(res);
          form.resetForm();
          this.contactInfo = {};
          this.messageService.add({
            severity: 'success',
            detail: res.message,
          });
        });
    }
  }

  navigate(slug: any) {
    console.log(slug);
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.onSameUrlNavigation = 'reload';
    this.route.navigate([slug]);
  }

  applyForMember() {
    if (localStorage.getItem('access_token')) {
      this.route.navigateByUrl('/user-panel/dashboard');
    } else {
      this.websiteService.modalCheck.next(true);
    }
  }
  getAfdData(){
    console.log(this.websiteService.afdData);
    this.afdPages = this.websiteService.afdData
    
  }
  navigateToAwards() {
    // Open in new tab
    window.open('https://awards.ucciudaipur.com/', '_blank');
    // Close the dialog
    this.isAwards = false;
  }
 
}
